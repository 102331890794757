const customCss = {
  inputCss: {
    input: {
      color: "#c1c1c1",
    },
    marginBottom: 2,
    backgroundColor: "#474747",
    border: "1px solid",
    borderColor: "grey",
    borderRadius: "4px",
    fontFamily: '"Open Sans-Regular", Helvetica',
    fontSize: "16px",
    fontWeight: 400,
    letterSpacing: "0",
    lineHeight: "normal",
    padding: "0",
    position: "relative",
  },
  buttonCss: {
    marginBottom: 2,
    marginTop: 2,
    fontWeight: 700,
    backgroundColor: "#33c4eb",
    borderRadius: "20px",
    ":hover": {
      bgcolor: "#454545", // theme.palette.primary.main
      color: "white",
    },
  },
};

export default customCss;
