import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import mpTracker from '../library/mixpanel';

export default function TileGuides({
  title,
  subtitle,
  description,
  features,
  img,
  tagging,
  path,
  productId,
  stripeId,
  price,
  activeGuides,
  loading
}) {

  //Is this guide active?
  const isActive = activeGuides.find((guide) => guide.productId === productId);

  let css = {};
  if (loading || isActive) {
    css = { ...css, pointerEvents: 'none' };
    css = { ...css, opacity: '0.4' };
  }

  return (
    <Link
      onClick={() => mpTracker.track(tagging)} to={path} style={{ textDecoration: "none", ...css }}>
      <Card
        sx={{
          maxWidth: 345,
          margin: "10px",
          boxShadow: "0px 0px 0px 3px #dadada",
          borderRadius: "20px",
        }}
      >
        <CardMedia
          loading="lazy"
          component="img"
          sx={{ height: 486, objectPosition: "top" }}
          image={`/assets/guides/${img}`}
        />
        <CardContent
          sx={{ paddingTop: "10px", paddingBottom: "10px !important" }}
        >
          <Typography
            gutterBottom
            component="div"
            variant="h6"
            sx={{ marginBottom: "0" }}
          >
            {title}
          </Typography>
          <Typography variant="paraOpen" sx={{ color: "#000" }}>
            {subtitle}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
