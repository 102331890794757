import React, { useState, useCallback, Fragment } from "react";
import { useStytch, useStytchUser } from "@stytch/react";
import "./style.css";
import mpTracker from '../library/mixpanel';
import { Navigate } from "react-router-dom";
// ** MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Hidden } from "@mui/material";
import Typography from "@mui/material/Typography";
import { isValidEmail } from "../constants/isValidEmail";
import customCss from "../constants/customCss";
import {
  Grid,
  ErrorBox,
  TermsBox,
  TrackedLink
} from "../components/CustomElements";
import { errorMap } from "../constants/errorMsgMap";
import { StytchHeadlessClient } from '@stytch/vanilla-js/headless';

const stytch = new StytchHeadlessClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);


export const SignUp = () => {

  const { inputCss, buttonCss } = customCss;
  const [firstName, setFirstName] = useState("");
  const [generatedHash, setGeneratedHash] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const stytchClient = useStytch();
  const API_URL = process.env.REACT_APP_API_URL;
  // const { user } = useStytchUser();

  const signUp = async () => {
    setIsLoading(true);
    //Validate email
    /*if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      mpTracker.error_track('Sign Up Error', { message: 'Please enter a valid email address' });
      return;
    }
    if (!password) {
      setError("Please enter a password");
      mpTracker.error_track('Sign Up Error', { message: 'Please enter a password' });
      return;
    }
    
    if (!firstName || !lastName) {
      setError("Please enter your full name");
      mpTracker.error_track('Sign Up Error', { message: 'Please enter your full name' });
      return;
    } */
    /*
    const createdAt = new Date().toISOString();

    let signUpRes;
    try {
      // Create User
      signUpRes = await stytchClient.passwords.create({
        email: email.toLowerCase(),
        password,
        session_duration_minutes: 60,
      });
    } catch (e) {
      console.error("Password strength check failed. Message:", e?.message);
      setIsLoading(false);
      setError(errorMap[e.error_type] || errorMap["default"]);
      mpTracker.error_track('Sign Up Error', { message: errorMap[e.error_type] || errorMap["default"] });
      return;
    }

    // Update User
    try {

      const signUpResponse = signUpRes?.user;
      const stytchId = signUpResponse?.user_id;

      await stytchClient.user.update({
        name: {
          first_name: firstName,
          last_name: lastName,
        },
        untrusted_metadata: {
          source: "web",
          original_created_at: createdAt,
        },
      });
      mpTracker.track('Sign Up Success - Stytch');

      if (stytchId) {
        mpTracker.setPeopleProperties(stytchId, firstName, lastName, email);
        console.log(stytchId, firstName, lastName, email);
      }
      
    } catch (e) {
      // setIsLoading(false);
      // setError(errorMap[e.error_type] || errorMap["default"]);
      // return;
      mpTracker.error_track('Sign Up Error', { message: 'API Failed' });
      // Not Doing anything here as the user doesn't need to know if this api has failed.
    }
    */

    // Create user in our database if valid stycthId
    try {
      // const { user_id: stytchId } = signUpRes?.user || {};
      const CREATE_USER_URL = `${API_URL}/v1/users/create-user`;

      // Create user in our database if valid stycthId
      // if (stytchId) {
        try {
          const user = await fetch(CREATE_USER_URL, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email.toLowerCase(), 
              firstName,
              lastName,
              // stytchId,
              generatedHash,
              password
            }),
          });
          const responseData = await user.json();
          if (responseData.status_code === 200) {
            console.log(firstName, lastName, email, 'User created in Database')
            stytch.session.updateSession({ session_token: responseData.session_token, session_jwt: responseData.session_jwt});
            stytch.session.authenticate({session_duration_minutes: 60});
            mpTracker.track('Sign Up Success - PG');
            setRegistrationSuccessful(true);
          } else {
            console.log(responseData.message, "Error creating user");
            setError(responseData.message);
          }
          // responseData.message
          // mpTracker.setPeopleProperties(stytchId, firstName, lastName, email);
        } catch (e) {
          console.log(e.message, "Error creating user in PG");
          mpTracker.error_track('Sign Up Error', { message: "Error creating user in PG: " + e.message });
        }
      // }
    } catch (err) {
      // Handle sign-up error
      mpTracker.error_track('Sign Up Error', { message: "Message: " + err.message });
    }
    //window.location.href = "/packages";
    setIsLoading(false);
  };

  if (registrationSuccessful && !isLoading) {
    return <Navigate reloadDocument={true} to="/packages" />;
  }
  return (
    <>
      <Box className="content-center">
        <Grid
          container
          fullWidth
          sx={{ zIndex: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Grid
            item
            sm={12}
            md={12}
            lg={4}
            sx={{
              mb: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          ></Grid>
          <Grid item sm={12} md={12} lg={5} style={{ width: "100%", mb: 8 }}>
            <Typography variant="h3">Create an Account </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={5}
            mb={8}
            mt={4}
            style={{ width: "100%" }}
          >
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                id="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setError("");
                }}
                label="First Name"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                sx={{
                  ...inputCss,
                }}
                placeholder="First Name"
              />
              <TextField
                fullWidth
                id="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setError("");
                }}
                label="Last Name"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                sx={{
                  ...inputCss,
                }}
                placeholder="Last Name"
              />
              <TextField
                fullWidth
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                label="Email"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                sx={{
                  ...inputCss,
                }}
                placeholder="Email"
              />
              <TextField
                fullWidth
                id="password"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                label="Password"
                sx={{
                  ...inputCss,
                }}
                placeholder=""
              />
              <Hidden
                fullWidth
                id="generatedHash"
                onChange={(e) => {
                  setGeneratedHash(e.target.value);
                  setError("");
                }}
                label="Generated Hash"
                placeholder=""
              />
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={buttonCss}
                onClick={signUp}
              >
                Register
              </Button>
              <ErrorBox variant="body2">{error}</ErrorBox>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <TermsBox>
                  <Typography
                    variant="body2"
                    style={{
                      color: "#fff",
                      fontWeight: 300,
                      marginBottom: 20,
                    }}
                  >
                    By clicking{" "}
                    <span
                      className="open"
                      style={{ fontWeight: "800", color: "#fff" }}
                    >
                      Register
                    </span>{" "}
                    you agree to our{" "}
                    <TrackedLink eventName='signup - link - terms' to={"/terms-and-conditions"}>
                      Terms & Conditions
                    </TrackedLink>{" "}
                    and the{" "}
                    <TrackedLink style={{
                        color: "white",
                        marginLeft: 4,
                      }}
                      eventName='signup - link - privacy' to={"/privacy-policy"}>
                      Privacy Policy.
                    </TrackedLink>
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{
                      color: "white",
                      fontWeight: 300,
                      marginBottom: 20,
                    }}
                  >
                    Need Help? Visit{" "}
                    <TrackedLink eventName='signup - link - support' to="https://help.strngofficial.com/">
                      Support Center
                    </TrackedLink>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "white", fontWeight: 300 }}
                  >
                    Already have an account?{" "}
                    <TrackedLink eventName='signup - link - login' to="/auth">Login </TrackedLink>
                  </Typography>
                </TermsBox>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
