import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import mpTracker from '../library/mixpanel';

export default function Tile({ imgUrl, title, description, link, matches, hasActiveSubs, type, loading, tagging }) {
  const hideCss = (type, hasActiveSubs) => {
    let css = {};
    if (loading || (hasActiveSubs)) {
      css = { ...css, pointerEvents: 'none' };
      css = { ...css, opacity: '0.4' };
    }
    return css;
  }
  return (
    <Link to={link} onClick={() => mpTracker.track(tagging)} style={{ textDecoration: "none", ...hideCss(type, hasActiveSubs) }}>
      <Card
        sx={{
          margin: "10px",
          boxShadow: "0px 0px 0px 1px #dadada",
          borderRadius: "16px",
        }}
      >
        <CardMedia
          component="img"
          alt={title}
          raised
          
          loading="lazy"
          sx={{ height: 240, objectPosition: "top" }}
          image={imgUrl}
        />
        <CardContent sx={{ height: matches ? "380px" : "380px" }}>
          <Typography
            gutterBottom
            component="div"
            variant="h6"
            sx={{ marginBottom: "0" }}
          >
            {title}
          </Typography>
          <Typography
            className="open"
            variant="body2"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              fontFamily: "Open Sans",
              padding: "0 5px",
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
