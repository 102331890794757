import React, { useState } from "react";
import { useStytch } from "@stytch/react";

// ** MUI Components
import Box from "@mui/material/Box";
import mpTracker from '../library/mixpanel';
// import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import GridBase from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { isValidEmail } from "../constants/isValidEmail";
import customCss from "../constants/customCss";
import { TrackedLink } from "../components/CustomElements";
import { errorMap } from "../constants/errorMsgMap";
 
 
const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`;

//Helper method to get base url from current url
const getBaseUrl = () => {
  const url = window.location.href;
  const arr = url.split("/");
  const result = arr[0] + "//" + arr[2];
  return result;
};

const ForgotPassword = () => {
  const { inputCss, buttonCss } = customCss;

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const stytchClient = useStytch();
  const resetPassword = () => {
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      mpTracker.error_track('Reset Error', {message: "Please enter a valid email address"});
      return;
    }
    stytchClient.passwords
      .resetByEmailStart({
        email,
        reset_password_redirect_url: `${getBaseUrl()}/reset`,
        reset_password_template_id: "reset_password_without_a_login_option_",
        // reset_password_expiration_minutes: 10,
        login_redirect_url: `${getBaseUrl()}/auth`,
      })
      .then((data) => {
        // Redirect to the home page after initiating the password reset
        // return <Navigate to="/" />;
        if (data?.request_id) {
          setEmailSent(true);
          mpTracker.track('Reset Email Sent - Stytch');
          setError("");
        }
      })
      .catch((error) => {
        // Handle password reset error
        console.error("Password reset failed:", error);
        mpTracker.error_track('Reset Error', {message: error.message + ' ' + error});
        setError(errorMap[error.error_type] || errorMap["default"]);
      });
  };

  if (emailSent) {
    return (
      // If the password is reset, display a success message and a link to go back to the home page
      <>
        <Grid
          container
          fullWidth
          sx={{ zIndex: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            sx={{ mb: 1 }}
            style={{ width: "100%" }}
          >
            <Typography variant="paraOpen" mt={2}>
              An email with instructions on how to reset your password has been
              sent to {email}
            </Typography>
          </Grid>
        </Grid>

        <TrackedLink eventName='forget - link - home' to="/">Back to Home</TrackedLink>
      </>
    );
  }

  return (
    <>
      <Box className="content-center">
        <Grid
          container
          fullWidth
          sx={{ zIndex: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            sx={{ mb: 6 }}
            style={{ width: "100%" }}
          >
            <Typography variant="h3">Forgot your password? </Typography>

            <Typography variant="paraOpen" mt={2}>
              Please enter your email below to recover your account using an
              email code.{" "}
            </Typography>
          </Grid>

          <Grid item sm={12} md={12} lg={4} style={{ width: "100%" }}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                label="Email"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                sx={{
                  ...inputCss,
                  mb: 3,
                }}
                placeholder="hello@strngofficial.com"
              />
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={buttonCss}
                onClick={resetPassword}
              >
                Reset
              </Button>
              <div
                variant="body2"
                style={{
                  color: "#F00",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "25.6px",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                {error}
              </div>
              <TrackedLink eventName='forget - link - home' style={{ color: "white" }} to="/">
                Back to Home
              </TrackedLink>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ForgotPassword;
