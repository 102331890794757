/*******
*******

MMMMMNOdlllox00dccccccccccccccccccclldONMMMXdcllkNMMMMMXdccoKMMMWKxolllodOXMMMMM
MMMWk,       .'.                       ':xWk.   .lXMMMMk.  .xMNx;.        .lKMMM
MMMk.  .cdol;;xkddd;   .lddo'   'xkd'    .kk.     ;0MMMk.  .x0;    ;lddl, .:KMMM
MMMd   .xXWMWWWMMMMd   ,0MMNc   :NMMx.    dk.      'kWMO.  .o:   .kWMMMMNOONMMMM
MMMXc.   .:oONMMMMMd   '0MMN:   'dxo'    ,0k.  .;.  .oNk.  .,.  .dWMMKxxxxxxxONM
MMMMWOl,.    'dNMMMd   '0MMN:         'lxKWk.  .x0,   cd.  .'.  .kMMMo       .OM
MMMWWMMWKx:.   lNMMd   '0MMN:   .'   .oNMMMk.  .xMXc   .   .:'   cNMMKxdd;   .OM
MMNo:oOXNXx.   :NMMd   ,0MMNc   :Kd.   ;dOWk.  .xMMNd.     .xd.   ;xKNNKd'   lNM
MNl    .'..   .kMMMd   ,0MMNc   :NW0;    .od.  .xMMMWk.    .xWk,    ....   .dNMM
MW0d:,......;oKWMMMx...;KMMWl...lNMMNd'....'...'kMMMMM0;...'kMMNkl,.....,:dKMMMM
MMMMMNKOkO0XWMMMMMMXOkk0WMMWKkkkKWMMMWKkkkkkkkkONMMMMMMXOkkONMMMMMWKOkOKNMMMMMMM

- - - - -
STRNG - My STRNG Web App
- - - - -
*******
*******/
import "./App.css";
import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Mixpanel } from './library/mixpanel';
import { StytchProvider } from "@stytch/react";
import { StytchHeadlessClient } from "@stytch/vanilla-js/headless";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LandingHome from "./pages/LandingHome";
import SuccessPage from "./pages/SuccessPage";
import MessagePage from "./pages/MessagePage";

import { Login } from "./pages/Login";
import { SignUp } from "./pages/SignUp";
import Members from "./pages/Members";
import { ResetPassword } from "./pages/ResetPassword";
import { MemberPage } from "./pages/MemberPage"; // Import the MemberPage component
import Subscriptions from "./pages/Subscriptions";
import Guides from "./pages/Guides";
import GuideDetail from "./pages/GuideDetail";
import ForgotPassword from "./pages/ForgotPassword";
import CancelledPage from "./pages/Cancelled";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";
import ManageMembership from "./pages/ManageMembership";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Define the isLoggedIn state

  // Mixpanel.track('sxy', 'yup');

  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  const stytchClient = useMemo(() => {
    return new StytchHeadlessClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);
  }, []);

  const styles = {
    container: {
      width: "100%",
    },
    buttons: {
      primary: {
        backgroundColor: "#4A37BE",
        borderColor: "#4A37BE",
      },
    },
  };

  const handleLogout = useCallback(() => {
    // Your logout logic here using stytchClient
    stytchClient.session.revoke();
    // After logout, set isLoggedIn to false
    setIsLoggedIn(false);
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }, [stytchClient]);

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: `"Open Sans", "League Spartan", "Arial", sans-serif`,
      },

      h3: {
        fontFamily: "League Spartan",
        color: "#ffffff",
        fontWeight: 700,
        left: "0",
        letterSpacing: "0",
        lineHeight: "38.4px",
        textAlign: "center",
        top: "0",
        textTransform: "uppercase",
      },
      h6: {
        fontWeight: 700,
        textTransform: "uppercase",
        fontSize: "1rem",
        fontFamily: "League Spartan",
      },
      paraOpen: {
        display: "block",
        fontFamily: "Open Sans",
        fontSize: 16,
        color: "#FFFFFF",
        lineHeight: "1.43",
        letterSpacing: "0.01071em",
      },
      paraOpenBold: {
        display: "block",
        fontFamily: "Open Sans",
        fontSize: 16,
        color: "#FFFFFF",
        lineHeight: "1.43",
        fontWeight: 700,
        letterSpacing: "0.01071em",
      },
    },
  });

  return (
    <div className="App">
      <Router>
        {/* <nav>
          <Link to="/signup">Register</Link>
          <Link to="/auth">Login</Link>
          <Link to="/reset">Reset Password</Link>
        </nav> */}
        <ThemeProvider theme={theme}>
          <StytchProvider stytch={stytchClient} styles={styles}>
            <Routes>
              {/* Home Page */}
              <Route path="/" element={<LandingHome />} />

              {/* Login Page */}
              <Route
                path="/auth"
                element={<Login onLogin={() => setIsLoggedIn(true)} />}
              />

              {/* SignUp Page */}
              <Route path="/signup" element={<SignUp />} />

              <Route path="/packages" element={<Members />} />

              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/manage-membership" element={<ManageMembership />} />
              <Route path="/guides" element={<Guides />} />
              <Route path="/guides/:id" element={<GuideDetail />} />

              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancelled" element={<CancelledPage />} />

              {/* Reset Password Page */}
              <Route path="/reset" element={<ResetPassword />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="*" element={<NotFound />} />

              {/* Member Page */}
              <Route
                path="/member"
                element={
                  isLoggedIn ? (
                    <MemberPage onLogout={handleLogout} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
            </Routes>
          </StytchProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
