import mixpanel from 'mixpanel-browser';

class MixpanelTracker {
  constructor() {
    // Initialize Mixpanel with STRNG token and configuration options
    mixpanel.init("0d44b58c4c99a2119172c828b9e8f2b1", {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
    this.setupEventListeners();
  }

  // Identify a user with a unique ID
  identify(userId) {
    mixpanel.identify(userId);
  }

  // Assign an alias to a user
  alias(id) {
    mixpanel.alias(id);
  }

  // Track an event with optional properties
  error_track(name, props) {
    mixpanel.track(name, props);
    console.log(name, props)
  }

  // Track an event with optional properties
  track(name) {
    mixpanel.track(name);
    console.log(name)
  }

  // Track an event with optional properties
  trackWithProps(name, props) {
    mixpanel.track(name, props);
    console.log(name, props)
  }

  // Set properties for a user in Mixpanel
  // From: https://docs.mixpanel.com/docs/data-structure/user-profiles
  setPeopleProperties(userId, userFirstName, userLastName, userEmail) {
    mixpanel.people.set({
      $first_name: userFirstName,
      $last_name: userLastName,
      $email: userEmail.toLowerCase(),
      // Any other properties specific to your user profile
    });
    // Identify the user when setting properties
    this.identify(userId);
  }

   // Function to track user events directly within the class
   userEvent(eventName, userId, userEmail, userFirstName, userLastName) {
    this.identify(userId);
    this.track(eventName);
    this.setPeopleProperties(userId, userFirstName, userLastName, userEmail.toLowerCase());
  }

  // Additional methods can be added for more specific tracking or custom events in future
  // STRNG Fitness events or methods can be added here..

  // Function to add event listeners to specific HTML elements
  setupEventListeners() {
    // To do is to move the footer and header into React components
    // -- They sit outside of React Router and are not part of the React app
    // -- They are just HTML elements that are rendered on the page
    // -- They are not part of the React Router DOM
    // -- They are not part of the React app
    // -- Header and Footer elements are listed in index.html

    const elements = {
      'header-link-logo': 'header - link - logo',
      'header-link-login': 'header - link - login',
      'header-link-logout': 'header - link - logout',
      'header-link-manage-subscription': 'header - link - manage subscription',
      'footer-link-download-ios': 'footer - link - download ios',
      'footer-link-download-android': 'footer - link - download android',
      'footer-link-shop': 'footer - link - shop',
      'footer-link-about': 'footer - link - about',
      'footer-link-ambassador': 'footer - link - ambassador',
      'footer-link-support': 'footer - link - support',
      'footer-link-terms': 'footer - link - terms',
      'footer-link-privacy': 'footer - link - privacy',
    };
    
    Object.entries(elements).forEach(([dataTrackingValue, eventName]) => {
      const trackedElements = document.querySelectorAll(`[data-tracking="${dataTrackingValue}"]`);
      
      trackedElements.forEach((element) => {
        element.addEventListener('click', (event) => {
          // event.preventDefault(); // Prevent default action if needed
          console.log(eventName);
          mixpanel.track(eventName);
        });
      });
    });
  }
}

// Create the STRNG instance of MixpanelTracker
const mpTracker = new MixpanelTracker();

export default mpTracker;