import React, { useState, useCallback, Fragment, useEffect } from "react";
import { useStytch, useStytchUser } from "@stytch/react";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import StrngButton from "./StrngButton";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { ErrorBox, LinkStyled } from "./CustomElements";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import { List, ListItem, ListItemText } from "@mui/material";
import Select from 'react-select';
import StrngTextArea from "./StrngTextArea";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import Chip from '@mui/material/Chip';
import { getStytchSessionToken } from "../services/stytch.service";

import CloseIcon from '@mui/icons-material/Close';
//import iconbutton
import IconButton from '@mui/material/IconButton';
const API_URL = process.env.REACT_APP_API_URL;


const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.3;
      padding: 12px;
      border-radius: 4px;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

      &:hover {
      border-color: ${blue[400]};
      }

      &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      }

      // firefox
      &:focus-visible {
      outline: 0;
      }
`,
);

export const queryTypes = [
  { value: 'cancel', label: 'Cancel' },
  { value: 'update', label: 'Update' },
  { value: 'other', label: 'Other' },
];

const storeMap = {
  "play_store": "Play Store",
  "app_store": "Apple Store",
  "stripe": "Web",
}

const currencyMapper = {
  "USD": "$",
  "CAD": "$",
  "AUD": "$",
  "GBP": "£",
  "DEFAULT": "£",
  "£": "£"
}
export default function TileManageSubs({ subscription = {}, guide = {}, link, frequency, type }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [query, setQuery] = useState("");
  const [requestNo, setRequestNo] = useState("");
  const [queryType, setQueryType] = useState("");

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const notify = (msg, error) => {
    if (error) {
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  //get the trial end date
  const {
    trial_end: trialEnd,
    purchase_date: purchaseDateSubs,
    subscriptionPrice,
    currency: currencySubs,
    id: idSub,
    store: subscriptionStore,
    store_transaction_id: storeTransactionId,
    subscriptionMonths: titleSubscription,
    currencySymbol: currencySymbolSubs,
  } = subscription;

  let { priceOriginal: priceOriginalSubs } = subscription
  const {
    title: guideTitle,
    price: guidePrice,
    store: guideStore,
    id: idGuide,
    priceOriginal: priceOriginalGuide,
    currency: currencyGuide,
    purchase_date: purchaseDateGuide,
    currencySymbol: currencySymbolGuide
  } = guide;
  const title = titleSubscription || guideTitle;
  const store = subscriptionStore || guideStore;

  const currencySymbol = currencySymbolSubs || currencySymbolGuide;
  const currency = currencySubs || currencyGuide;
  priceOriginalSubs = ` ${currencySymbol}${priceOriginalSubs}`;
  // priceOriginalSubs = ` ${currencyMapper[currency?.toUpperCase() || "DEFAULT"]}${priceOriginalSubs}`;

  let price = priceOriginalSubs || guidePrice;
  // let price = subscriptionPrice || guidePrice;

  // let price = priceOriginalSubs || priceOriginalGuide;
  // price = ` ${currencyMapper[currency?.toUpperCase() || "DEFAULT"]}${price / 100}`;

  const purchaseDate = purchaseDateSubs || purchaseDateGuide;
  const subscriptionId = idSub || storeTransactionId || idGuide;

  //Check if trial is active
  //If trial is active, then show the trial end date

  const isTrialActive = trialEnd > Date.now() / 1000;

  const date = new Date(trialEnd * 1000);
  const trialEndDate = moment(date).format("MMM Do 'YY");

  const purchaseDateFormatted = moment(purchaseDate).format("MMM DD, YYYY");

  const { user } = useStytchUser();
  const { name } = user || {};
  const { first_name: firstName, last_name: lastName } = name || {};


  const email = user.emails?.[0]?.email || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRequestNo("");
    setError("");
    setQuery("");
    setQueryType("");
  };

  /**
   *  Create ticket on Zendesk
   * @param {*} obj
   */
  const handleSubmit = async (obj) => {

    if (!query?.length) {
      setError("Please enter your query in the text area before resubmitting.")
      return;
    }

    if (!queryType?.value) {
      setError("Please select a request type from the dropdown.")
      return;
    }
    //set loading to true
    setLoading(true);
    const ZENDESK_URL = `${API_URL}/v1/zendesk/createTicket`;
    // await new Promise(resolve => setTimeout(resolve, 6000));

    const {
      subscriptionId,
      title,
      price,
      type,
      email,
    } = obj;

    // commentBody, priortiy = 'urgent', subject
    const commentBody = `Subscription ID - ${subscriptionId} \n Email ID - ${email}  \n Request Type "${queryType.value}" for ${title} ${type != 'guide' ? `with subscription price ${price}` : ''}  \n Request - ${query}.`;
    const subject = `Thanks for contacting STRNG. ${type} Subscription Request.`;
    const priortiy = "urgent";

    try {
      const zenRes = await fetch(ZENDESK_URL, {
        method: "post",
        headers: { 
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${getStytchSessionToken()}` 
        },
        body: JSON.stringify({
          commentBody,
          email,
          priortiy,
          subject,
          query,
          queryType: queryType.value,
        }),
      });

      const zenResJson = await zenRes.json();
      setRequestNo(zenResJson?.res?.ticket?.id);
      setError("");
      // notify("Ticket created successfully", false);
      setOpen(false);
      setOpenSuccess(true);
    } catch (e) {
      setError(e.message);
      setOpenSuccess(false);
      // notify("Error in creating Ticket", true);
      console.log(e.message, "Error creating ticket in Support");
    }

    // set loading to false
    setQuery('')
    setQueryType('')
    setLoading(false);
  };


  return (
    <Link to={link} style={{ textDecoration: "none", width: "100%" }}>
      {/* <ToastContainer /> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Box display="flex" alignItems="right" style={{ position: 'absolute', right: 20 }}>
            {/* <Box flexGrow={1} >{title}</Box> */}
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ height: '350px', display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
          <DialogContentText style={{ padding: 20, paddingBottom: 0 }}>
            Thanks for choosing STRNG. Please select a reason for contacting us today via the dropdown below.
          </DialogContentText>

          <DialogActions style={{ flexDirection: 'column', display: "flex", padding: 20, paddingBottom: 0, width: '100%' }}>
            <Grid container>
              <Grid item xs={12} p={1}>
                <Select
                  className="membershipSelect"
                  // defaultValue={queryTypes[0]}
                  styles={{
                    singleValue: provided => ({
                      ...provided,
                      fontFamily: 'Open Sans !important',
                      color: '#0009',
                      fontWeight: 400,
                      lineHeight: 1.2,
                      color: '#1C2025',
                      padding: '2px 0px',
                      borderRadius: '4px',
                      fontSize: '1rem',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontFamily: 'Open Sans !important',
                      color: '#0009',
                      fontWeight: 400,
                      lineHeight: 1.2,
                      color: '#1C2025',
                      padding: '10px 12px',
                      borderRadius: '4px',
                      fontSize: '1rem',
                      backgroundColor: state.isSelected ? "(189,197,209,.3)" : "white",
                    }),
                    clearIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      fontFamily: 'Open Sans !important',
                      color: 'red'
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontFamily: 'Open Sans !important',
                      fontWeight: 400,
                      lineHeight: 1.2,
                      color: '#0009',
                      padding: '3px 2px',
                      borderRadius: '4px',
                      fontSize: '1rem',

                    }),
                  }}
                  isSearchable={true}
                  onChange={(selOption) => setQueryType(selOption)}
                  name="queryTypes"
                  options={queryTypes}
                />
              </Grid>
              <Grid item xs={12} p={1}>
                <Textarea
                  key="query"
                  maxRows={4}
                  minsRows={4}
                  aria-label="Please type your request here"
                  placeholder="Please type your request here..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)} />
              </Grid>
            </Grid>

            <Grid container justifyContent={"center"} style={{ marginTop: 10 }}>
              <Grid item xs={4} display={"flex"}>
                {/* <StrngButton
                  text={'Cancel'}
                  eventName={`manage-subs - cancel - ${subscriptionId}`}
                  onClick={() => {
                    setOpen(false)
                  }}></StrngButton> */}
                <StrngButton
                  text={loading ? 'Loading.' : 'Submit'}
                  eventName={`manage-subs - query - ${subscriptionId}`}
                  sx={{ ml: 1, width: '100%' }}
                  onClick={async () => {
                    await handleSubmit({
                      subscriptionId,
                      title,
                      price,
                      frequency,
                      type: "Modify",
                      email,
                    })
                    await new Promise(resolve => setTimeout(resolve, 1000));
                  }}></StrngButton>

              </Grid>
            </Grid>
          </DialogActions>
          <DialogContentText style={{ padding: 10 }}>
            {error && <ErrorBox>{error}</ErrorBox>}
          </DialogContentText>

        </DialogContent>
      </Dialog>
      {/* //Add Success dialog */}
      <Dialog open={openSuccess} onClose={handleCloseSuccess}>
        <DialogTitle disableTypography>
          <Box display="flex" alignItems="right" style={{ position: 'absolute', right: 20 }}>
            {/* <Box flexGrow={1} >{title}</Box> */}
            <Box>
              <IconButton onClick={handleCloseSuccess}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ height: '250px', display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
          <DialogContentText style={{ padding: 20, paddingBottom: 0 }}>
            Thank you, your request has been sent to our customer service team and we will be in touch within 48 hours. Your service request number is {requestNo}
          </DialogContentText>
          <DialogActions style={{ flexDirection: 'column', display: "flex", padding: 20, paddingBottom: 0, width: '100%' }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={4} display={"flex"}>
                <StrngButton
                  text={'Okay'}
                  eventName={`manage-subs - success - ${subscriptionId}`}
                  onClick={() => {
                    setOpenSuccess(false)
                  }}></StrngButton>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Card
        sx={{
          margin: "10px",
          boxShadow: "0px 0px 0px 1px #dadada",
          borderRadius: "16px",
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          style={{ padding: 10 }}
        >
          <Grid
            item
            xs={4}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Typography
              gutterBottom
              component="div"
              variant="h6"
              sx={{ marginBottom: "0" }}
            >
              {title}
            </Typography>
            {purchaseDateFormatted && (
              <Typography
                gutterBottom
                component="div"
                variant="paraOpen"
                sx={{ marginBottom: "0", color: "black" }}
              >

                Purchased on <span className="open">{purchaseDateFormatted}</span>
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} style={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
            {
              type != "guide" ?

                <Typography
                  gutterBottom
                  component="div"
                  variant="h6"
                  sx={{ marginBottom: "0" }}
                >
                  {price}
                </Typography>
                : ''
            }
            <Typography
              gutterBottom
              component="div"
              variant="h6"
              sx={{ marginBottom: "0" }}
            >
              {storeMap[store]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            display={"flex"}
            justifyContent={"right"}
            flexDirection={"row"}
          >

            <StrngButton
              text={"Manage"}
              onClick={() =>
                handleClickOpen()
              }
              showFullWidth={false}
              size="small"
              sx={{ m: 0, p: 0, ml: 1, pl: 3, pr: 3 }}
            ></StrngButton>
            {/* <StrngButton
              text={"Cancel"}
              onClick={() =>
                handleSubmit({
                  subscriptionId,
                  title,
                  amount,
                  interval,
                  interval_count,
                  currency,
                  frequency,
                  type: "cancel",
                  email,
                })
              }
              size="small"
              showFullWidth={false}
              sx={{ m: 0, p: 0, ml: 1, pl: 3, pr: 3 }}
            ></StrngButton> */}
          </Grid>
        </Grid>

      </Card>
    </Link >
  );
}
