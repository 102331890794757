import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import mpTracker from '../library/mixpanel';

// Define your custom button component
const StrngBackHomeButton = ({ text, to, eventName, sx = {} }) => {

    const handleClick = () => {
        // Call the mpTracker function with the provided eventName when the button is clicked
        if (eventName) {
            mpTracker.track(eventName);
        }
    };

    return (
        <Button
        fullWidth
        size="large"
        variant="contained"
        sx={{
            ...sx,
            ":hover": {
            bgcolor: "#777", // theme.palette.primary.main
            color: "white",
            },
            marginBottom: 7,
            fontWeight: 700,
            backgroundColor: "#454545",
            borderRadius: "20px",
            color: "white",
        }}
        onClick={handleClick}
        href={to}
        >
        {text}
        </Button>
    );
};

StrngBackHomeButton.propTypes = {
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    eventName: PropTypes.string, 
};

export default StrngBackHomeButton;
