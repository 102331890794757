
import { guidesProd } from "../constants/guides";
import { guidesStaging } from "../constants/guides-staging";

import { subscriptionsProd } from "../constants/subscriptions";
import { subscriptionsStaging } from "../constants/subscriptions-staging";

// import { getStytchSessionToken } from "./stytch.service";
 

const API_URL = process.env.REACT_APP_API_URL;
const isProd = process.env.REACT_APP_VERCEL_ENV === "prod" ||
    process.env.REACT_APP_VERCEL_ENV === "production";
const guidesStatic =
    isProd
        ? guidesProd
        : guidesStaging;

const subscriptionsStatic =
    isProd
        ? subscriptionsProd
        : subscriptionsStaging;

// get all subscriptions and guides for a user
export async function getActiveSubsAndGuides(email, sessionId) {
  
    // email = 'g.stara91@gmail.com';
    try {
        const subsRes = await fetch(`${API_URL}/v1/subscriptions/active`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'authorization': `Bearer ${sessionId}`,
            },
            body: JSON.stringify({ email }),
        });

        const subsData = await subsRes.json();
        const { subscriptions, nonSubscriptions } = subsData;

        const ret = { subscriptions: [], nonSubscriptions: [] };
        // process subs
        if (subscriptions) {
            subscriptions.forEach((sub) => {
                const { productId } = sub;
                const subscriptionLoc = subscriptionsStatic.find(
                    (subStatic) => subStatic.appCode === productId || subStatic.stripeProdID === productId
                );
                if (subscriptionLoc) {
                    sub.priceOriginal = sub.price;
                    sub = { ...sub, ...subscriptionLoc };
                    ret.subscriptions.push(sub);
                }
            });
        }

        // process guides
        if (nonSubscriptions) {
            nonSubscriptions.forEach((guide) => {
                const { productId } = guide;
                const guideLoc = guidesStatic.find(
                    (guideStatic) => guideStatic.ios === productId || guideStatic.android === productId || guideStatic.stripeProdID === productId
                );
                if (guideLoc) {
                    guide.priceOriginal = guide.price;
                    guide = { ...guide, ...guideLoc };
                    let currency = guide.currency;
                    if (!currency) {
                        guide.currency = '£';
                        currency = '£';
                    }

                    guide.price = `${currency}${guide.price}`;
                    ret.nonSubscriptions.push(guide);
                }
            });

        }
        return ret
    } catch (error) {
        console.error(error);
        return null;
    }
}
