import { styled } from "@mui/material/styles";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import ListItem from "@mui/material/ListItem";
import { Link as RouterLink } from "react-router-dom";
import mpTracker from "../library/mixpanel";

import GridBase from "@mui/material/Grid";

export const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginTop: theme.spacing(0),
  padding: theme.spacing(0, 1.5),
  marginBottom: theme.spacing(2),
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: "#ffffff",
  },
  width: "100%",
}));

export const ErrorBox = styled("div")(({ theme }) => ({
  color: "#F00",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "25.6px",
  width: "100%",
  marginTop: "0px",
}));

export const LinkStyled = styled(RouterLink)(({ theme, fontWeight = 300, sx = {} }) => ({
  fontSize: "0.875rem",
  textDecoration: "underline",
  fontFamily: "Open Sans",
  // color: "#c1c1c1", //Should be this. but commenting it  out to match other links
  color: "#ffffff",
  fontWeight,
  cursor: "pointer",
  ...sx,
}));

// Enhanced LinkStyled component with tracking functionality
export const TrackedLink = ({ eventName, to, children, onClick }) => {
  const handleMixPanelClick = () => {
    mpTracker.track(eventName);
    if (onClick) {
      onClick();
    }
  };
  return (
    <LinkStyled to={to} onClick={handleMixPanelClick}>
      {children}
    </LinkStyled>
  );
};

export const TermsBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  marginTop: "2em",
  flexDirection: "column",
}));

export const ListItemStyled = styled("ListItem")(({ theme }) => ({
  display: "list-item",
  fontFamily: "Open Sans",
}));
