import React from "react";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import { TrackedLink } from "../components/CustomElements";

const CancelledPage = ({ sessionId }) => {
  const query = new URLSearchParams(window.location.search);
  const error = query.get("error");

  return (
    <section>
      <Grid container flexDirection={"column"}>
        <Grid item sm="12">
          <div className="product Box-root">
            <div className="description Box-root">
              <Typography variant="h3">Payment Failure </Typography>
              <h3
                style={{ color: "white", fontSize: "22px", marginTop: "1em" }}
              >
                Subscription to your plan was not successful.
              </h3>
              {error ? (
                <h3
                  style={{ color: "white", fontSize: "18px", marginTop: "3em" }}
                >
                  Error Message - {error}
                </h3>
              ) : null}
              <h3
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginTop: error ? "1em" : "3em",
                }}
              >
                Please try again.
              </h3>
              <h3
                style={{ color: "white", fontSize: "18px", marginTop: "3em" }}
              >
                Download the App.
              </h3>
            </div>
          </div>
        </Grid>
        <Grid item sm="12">
          {/* Playstore and AppStore Download links with images */}

          <div
            className="product Box-root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div className="Box-root">
              <TrackedLink
                eventName='cancelled - download - ios'
                to="https://apps.apple.com/us/app/strng/id1492688256?ls=1"
                style={{
                  margin: 10,
                }}
              >
                <img
                  src={"/assets/download_ios.svg"}
                  className="app-icon"
                  alt="logo"
                />
              </TrackedLink>
            </div>
            <div className="Box-root">
              <TrackedLink
                eventName='cancelled - download - android'
                to="https://play.google.com/store/apps/details?id=com.strong.andsxy&pli=1"
                style={{ ":hover": { opacity: "0.7", margin: 10 } }}
              >
                <img
                  src={"/assets/download_android.svg"}
                  className="app-icon"
                  alt="logo"
                />
              </TrackedLink>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default CancelledPage;
