import React from "react";
import { Link } from "react-router-dom";
import { useStytch, useStytchUser } from "@stytch/react";

export const MemberPage = ({ onLogout }) => {
  const stytchClient = useStytch();
  const { user } = useStytchUser();

  return (
    <div>
      <h2>Welcome to the Member Page</h2>
      {user ? (
        <>
          <p>Welcome, {JSON.stringify(user.emails[0].email, null, 2)}</p>
          <h3>User Data:</h3>
          <pre className="code-block">
            <code>{JSON.stringify(user, null, 2)}</code>
          </pre>
        </>
      ) : (
        <p>Loading user data...</p>
      )}
      <button onClick={onLogout}>Logout</button>
      <Link to="/">Back to Home</Link>
    </div>
  );
};
