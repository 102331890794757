import React from "react";
import "./style.css";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Paper, ListItemText } from "@mui/material";
import { ListItemStyled, TrackedLink } from "../components/CustomElements";
import { Link, useNavigate } from "react-router-dom";
import StrngButton from "../components/StrngButton";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <section>
      <Grid container flexDirection={"column"} alignItems={"center"}>
        <Grid item sm="12" md="8">
          <Paper elevation={3} style={{ padding: "2em", textAlign: "left" }}>
            <Grid container flexDirection={"column"} alignItems={"center"}>
              <Grid item sm="6">
                <Typography variant="h3">
                  <strong>Privacy Policy</strong>
                </Typography>
              </Grid>
              <Grid item sm="6" alignItems={"right"}>
                <StrngButton
                  sx={{ mt: 5 }}
                  onClick={() => {
                    navigate("/");
                  }}
                  text={`Home`}
                ></StrngButton>
              </Grid>
            </Grid>
            <Typography variant="h6" sx={{ mb: 1 }}>
              <strong>PURPOSE OF THIS PRIVACY POLICY</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              Liro Fitness LLC, respects your privacy and is committed to
              protecting your personal data. This privacy policy will inform you
              as to how we look after your personal data when you visit our
              website and tell you about your privacy rights and how the law
              protects you.
            </Typography>

            <Typography variant="h6" sx={{ mb: 1 }}>
              <strong>IMPORTANT INFORMATION AND WHO WE ARE</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              Purpose of this privacy policy
            </Typography>
            <Typography sx={{ mb: 1 }}>
              This privacy policy aims to give you information on how Liro
              Fitness LLC, collects and processes your personal data through
              your use of this website, including any data you may provide
              through this website when you subscribe or purchase a product or
              service or take part in a competition.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              This website is not intended for children and we do not knowingly
              collect data relating to children.
            </Typography>

            <Typography variant="h6" sx={{ mb: 1 }}>
              <strong>CONTACT DETAILS</strong>
            </Typography>
            <Typography sx={{ mb: 1 }}>
              If you have any questions about this privacy policy or our privacy
              practices, please contact us in the following ways:
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Full name of legal entity: STRONG&SXY LTD
            </Typography>

            <Typography sx={{ mb: 1 }}>
              Email address: hello@strngofficial.com 
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Registered address: Sharjah Media City, Sharjah, UAE.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              You have the right to make a complaint at any time to the
              Information Commissioner's Office (ICO), the UK regulator for data
              protection issues (www.ico.org.uk). We would, however, appreciate
              the chance to deal with your concerns before you approach the ICO
              so please contact us in the first instance.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
              <strong>WE COLLECT THE FOLLOWING TYPES OF INFORMATION</strong>
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                  <Typography>
                    1. Information you provide to us directly: when you register
                    for an account via email, we collect your full name,
                    password, and email address.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>2. Technical information we collect:</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    color: "white",
                    mt: 0,
                    pt: 0,
                  }}
                >
                  <ListItemStyled sx={{ mb: 2 }}>
                    Any use of Liro Fitness LLC’s website and/or software may
                    result in the collection of technical information such as
                    your computer’s IP address, mobile device identifier, meta
                    data, operating system, browser name/version, the referring
                    web page, requested page; such information is used to help
                    us understand the overall usage pattern of our website and
                    software.
                  </ListItemStyled>
                  <ListItemStyled sx={{ mb: 2 }}>
                    We also use third-party analytics tools to help us measure
                    how you use our website. These tools collect information
                    sent by your device or our Service, including how you like
                    to use our software and website which can help us in
                    improving our Service.
                  </ListItemStyled>
                  <ListItemStyled sx={{ mb: 2 }}>
                    Liro Fitness LLC may invite you to provide information
                    through feedback on features on our website or software; we
                    may send out e-mail inviting feedback or offering products
                    and services; or we may ask you for information concerning
                    support of our products or services. Your response to these
                    communications may require you to submit personal
                    information and opinions.
                  </ListItemStyled>
                </List>
              </ListItem>
            </List>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>HOW WE USE YOUR INFORMATION</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              We may use information that we receive to:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                Help you efficiently access your information after you sign in
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Remember information so you will not have to re-enter it during
                your visit or the next time you visit the Service
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Provide personalised content and information to you and others,
                which could include online ads or other forms of marketing
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Provide, improve, test, and monitor the effectiveness of our
                Service
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                Develop and test new products and features
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                Monitor metrics such as total number of visitors, traffic, and
                demographic patterns
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                Diagnose or fix technology problems
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                Automatically update Liro Fitness LLC’s application on your
                device
              </ListItemStyled>
            </List>

            <Typography sx={{ mb: 1 }}>
              We have set out below, a description of all the ways we plan to
              use your personal data, and which of the legal bases we rely on to
              do so. We have also identified what our legitimate interests are
              where appropriate.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Note that we may process your personal data for more than one
              lawful ground depending on the specific purpose for which we are
              using your data. Please contact us if you need details about the
              specific legal ground we are relying on to process your personal
              data where more than one ground has been set out below.
            </Typography>
            <Typography sx={{ mb: 1 }}>Purpose/Activity:</Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                To register you as a new customer
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                To process and deliver your order including:
                <List>
                  <ListItem>
                    <ListItemText>
                      (a) Manage payments, fees, and charges
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      (b) Collect and recover money owed to us
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                To manage our relationship with you which will include:
                <List>
                  <ListItem>
                    <ListItemText>
                      (a) Notifying you about changes to our terms or privacy
                      policy
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      (b) Asking you to leave a review or take a survey
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                To enable you to partake in a prize draw, competition or
                complete a survey
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                To administer and protect our business and this website
                (including troubleshooting, data analysis, testing, system
                maintenance, support, reporting and hosting of data)
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                To deliver relevant website content and advertisements to you
                and measure or understand the effectiveness of the advertising
                we serve to you
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                To use data analytics to improve our website, products/services,
                marketing, customer relationships and experiences
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                To make suggestions and recommendations to you about goods or
                services that may be of interest to you
              </ListItemStyled>
            </List>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>TYPE OF DATA</strong>
            </Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                Identity, contact, transaction, marketing and communications,
                identity, profile, usage, technical
              </ListItemStyled>
            </List>
            <Typography sx={{ mb: 1 }}>
              Lawful basis for processing including basis of legitimate interest
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                Performance of a contract with you
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Necessary for our legitimate interests (to recover debts due to
                us)
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Necessary to comply with a legal obligation
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Necessary for our legitimate interests (to keep our records
                updated and to study how customers use our products/services, to
                study how customers use our products/services, to develop them
                and grow our business)
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise)
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                {" "}
                Necessary for our legitimate interests (to study how customers
                use our products/services, to develop them, to grow our business
                and to inform our marketing strategy)
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                {" "}
                Necessary for our legitimate interests (to develop our
                products/services and grow our business)
              </ListItemStyled>
            </List>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>PURPOSE OF USING YOUR INFORMATION</strong>
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>MARKETING</strong>
            </Typography>
            <Typography sx={{ mb: 1 }}>
              We strive to provide you with choices regarding certain personal
              data uses, particularly around marketing and advertising.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>PROMOTIONAL OFFERS FROM US</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              We may use your Identity, Contact, Technical, Usage and Profile
              Data to form a view on what we think you may want or need, or what
              may be of interest to you. This is how we decide which products,
              services and offers may be relevant for you (we call this
              marketing).
            </Typography>

            <Typography sx={{ mb: 1 }}>
              You will receive marketing communications from us if you have
              requested information from us or purchased goods or services from
              us and you have not opted out of receiving that marketing.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>THIRD-PARTY MARKETING </strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              We will get your express opt-in consent before we share your
              personal data with any third party for marketing purposes.
            </Typography>

            <Typography sx={{ mb: 1 }}>
              Opting out You can ask us or third parties to stop sending you
              marketing messages at any time by following the opt-out links on
              any marketing message sent to you or by contacting us at any time.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Where you opt out of receiving these marketing messages, this will
              not apply to personal data provided to us as a result of a
              product/service purchase, warranty registration, product/service
              experience or other transactions.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>COOKIES</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              You can set your browser to refuse all or some browser cookies, or
              to alert you when websites set or access cookies. If you disable
              or refuse cookies, please note that some parts of this website may
              become inaccessible or not function properly.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>CHANGE OF PURPOSE</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              We will only use your personal data for the purposes for which we
              collected it, unless we reasonably consider that we need to use it
              for another reason and that reason is compatible with the original
              purpose. If you wish to get an explanation as to how the
              processing for the new purpose is compatible with the original
              purpose, please contact us.
            </Typography>

            <Typography sx={{ mb: 1 }}>
              If we need to use your personal data for an unrelated purpose, we
              will notify you and we will explain the legal basis which allows
              us to do so.
            </Typography>
            <Typography sx={{ mb: 1 }}>
              Please note that we may process your personal data without your
              knowledge or consent, in compliance with the above rules, where
              this is required or permitted by law.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>SHARING OF YOUR INFORMATION</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              We will not rent or sell your information to third parties outside
              of Liro Fitness LLC without your consent, except as noted in this
              Policy. Parties with whom we may share your information:
            </Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                We may share User Content not personal information with
                potential business partners, service providers as a way of
                improving our service and providing relevant content. This will
                be done under confidentiality terms.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                We may remove parts of data that can identify you and share
                anonymized data with other parties. We may also combine your
                information with other information in a way that it is no longer
                associated with you and share that aggregated information.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                We may be required to disclose your information without your
                prior consent by law for the following reasons:
                <List>
                  <ListItem>
                    <ListItemText>
                      a) Comply with law or legal process
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      b) Protect and defend our rights and property, or the
                      rights and property of a third party
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      c) Protect us against misuse or unauthorized use of any of
                      Liro Fitness LLC’s products, software, services, or other
                      proprietary materials
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      d) Prevent death or imminent bodily harm. prevent and
                      address fraud and other illegal activity
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItemStyled>
            </List>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong> HOW WE STORE YOUR INFORMATION</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>Storage and Processing:</Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                We use commercially reasonable precautions to help keep your
                personal information safe and stored securely. However, we
                cannot ensure the security of any information you transmit to
                Liro Fitness LLC or guarantee that information on the Service
                may not be accessed, disclosed, altered, or destroyed.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Please do your part to help us. You are responsible for
                maintaining the secrecy of your unique password and account
                information, and for controlling access to emails between you
                and Liro Fitness LLC, at all times.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Your privacy settings may also be affected by changes to the
                social media services in which you connect to. We are not
                responsible for the functionality, privacy, or security measures
                of any other organization.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Following termination or deactivation of your account, Liro
                Fitness LLC, its Affiliates, or its Service Providers may retain
                information (including your profile information) and User
                Content for a commercially reasonable time for backup, archival,
                and/or audit purposes.
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                Email our support to know about deleting your account team
                hello@strngofficial.com
              </ListItemStyled>
            </List>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>CHILDREN'S PRIVACY</strong>
            </Typography>

            <Typography sx={{ mb: 1 }}>
              Liro Fitness LLC does not knowingly collect or solicit any
              information from anyone under the age of 13 or knowingly allow
              such persons to register for the Service. The Service and its
              content are not directed at children under the age of 13. In the
              event that we learn that we have collected personal information
              from a child under age 13 without parental consent, we will delete
              that information as quickly as possible. If you believe that we
              might have any information from or about a child under 13, please
              contact us.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              <strong>OTHER WEBSITES AND SERVICES</strong>
            </Typography>
            <Typography sx={{ mb: 1 }}>
              We are not responsible for the practices employed by any websites
              or services linked to or from our Service, including the
              information or content contained within them. Please remember that
              when you use a link to go from our Service to another website or
              service, our Privacy Policy does not apply to those third-party
              websites or services. Your browsing and interaction on any
              third-party website or service, including those that have a link
              on our website/software, or are accessed through our web/in-app
              browser are subject to that third party's own rules and policies.
              In addition, you agree that we are not responsible and do not have
              control over any third-parties that you authorize to access your
              User Content. If you are using a third-party website or service
              and you allow them to access your User Content you do so at your
              own risk.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION
              REGULATION (GDPR)
            </Typography>

            <Typography sx={{ mb: 1 }}>
              If you are a resident of the EEA or the UK, you have the following
              data protection rights:
            </Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled sx={{ mb: 2 }}>
                If you wish to access, correct, update, or request deletion of
                your personal information, you can do so at any time by emailing
                us at hello@strngofficial.com.
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                In addition, you can object to the processing of your personal
                information, ask us to restrict the processing of your personal
                information, or request portability of your personal
                information. Again, you can exercise these rights by emailing
                us.
              </ListItemStyled>

              <ListItemStyled sx={{ mb: 2 }}>
                You have the right to opt-out of marketing communications we
                send you at any time. You can exercise this right by clicking on
                the "unsubscribe" or "opt-out" link in the marketing emails we
                send you. To opt-out of other forms of marketing, please contact
                us by email.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                Similarly, if we have collected and process your personal
                information with your consent, then you can withdraw your
                consent at any time. Withdrawing your consent will not affect
                the lawfulness of any processing we conducted prior to your
                withdrawal, nor will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </ListItemStyled>
              <ListItemStyled sx={{ mb: 2 }}>
                You have the right to complain to a data protection authority
                about our collection and use of your personal information. For
                more information, please contact your local data protection
                authority.
              </ListItemStyled>
            </List>

            <Typography sx={{ mb: 1 }}>
              We respond to all requests we receive from individuals wishing to
              exercise their data protection rights in accordance with
              applicable data protection laws.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              QUESTIONS
            </Typography>

            <Typography sx={{ mb: 1 }}>
              If you have any questions about this Privacy Policy or the
              Service, please find the appropriate support channel in the
              website settings or contact us at hello@strngofficial.com 
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              CHANGES TO OUR PRIVACY POLICY
            </Typography>

            <Typography sx={{ mb: 1 }}>
              Liro Fitness LLC may modify or update this Privacy Policy from
              time to time, so please review it periodically. We may provide you
              additional forms of notice of modifications or updates as
              appropriate under the circumstances. Your continued use of the
              website or the Service after any modification to this Privacy
              Policy will constitute your acceptance of such modification.
            </Typography>

            <Grid container flexDirection={"column"} alignItems={"center"}>
              <Grid item sm="4">
                <StrngButton
                  sx={{ mt: 5 }}
                  onClick={() => {
                    navigate("/");
                  }}
                  text={`Home`}
                ></StrngButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </section>
  );
};

export default Privacy;
