
// Function to get the headline for the subscriptions 
const getSubsHeadline = (subscriptions, guides) => {
    let headline = '';
    // if both subscriptions and guides
    if (subscriptions.length && guides.length) {
        headline = `You currently have ${subscriptions.length} active subscription${subscriptions.length > 1 ? "s" : ""} and have lifetime access to ${guides.length} guide${guides.length > 1 ? "s" : ""}. `;
    }
    // if no subscriptions and only guides
    if (!subscriptions.length && guides.length) {
        headline = `You currently have lifetime access to ${guides.length} guide${guides.length > 1 ? "s" : ""}.`;
    }

    // if no guides and only subscription
    if (!guides.length && subscriptions.length) {
        headline = `You currently have ${subscriptions.length} active subscription${subscriptions.length > 1 ? "s" : ""}.`;
    }
    return headline;
}

// export function
export {
    getSubsHeadline
}