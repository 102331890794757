import React, { useState, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import { useStytch, useStytchUser } from "@stytch/react";
import "./style.css";
import mpTracker from '../library/mixpanel';
// ** MUI Components
import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import GridBase from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ErrorBox, TrackedLink } from "../components/CustomElements";
import customCss from "../constants/customCss";
import { errorMap } from "../constants/errorMsgMap";

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`;
export const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { inputCss, buttonCss } = customCss;

  const stytchClient = useStytch();

  const token = new URLSearchParams(window.location.search).get("token");
  const resetPassword = useCallback(() => {
    if (!newPassword) {
      setError("Please enter a valid password");
      mpTracker.error_track('Reset Error', { message: 'Please enter a valid email address' });
      return;
    }
    stytchClient.passwords
      .resetByEmail({
        token,
        password: newPassword,
        session_duration_minutes: 60,
      })
      .then(() => {
        // Notify the user that the password has been reset
        setIsPasswordReset(true);
        mpTracker.track('Reset Email Sent - Stytch');
      })
      .catch((error) => {
        // Handle password reset error
        console.error("Password reset failed:", error);
        mpTracker.error_track('Reset Error', { message: error.message + ' ' + error });
        setError(errorMap[error.error_type] || errorMap["default"]);
      });
  }, [stytchClient, token, newPassword]);

  if (isPasswordReset) {
    return (
      // If the password is reset, display a success message and a link to go back to the home page
      <>
        <Typography variant="paraOpen" mt={2}>
          Password has been reset successfully.
        </Typography>
        <TrackedLink eventName='reset - link - home' to="/">Back to Home</TrackedLink>
      </>
    );
  }
  return (
    <>
      <Box className="content-center">
        <Grid
          container
          fullWidth
          sx={{ zIndex: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Grid
            item
            sm={12}
            md={12}
            lg={4}
            sx={{ mb: 6 }}
            style={{ width: "100%" }}
          >
            <Typography variant="h3">RESET PASSWORD </Typography>

            <Box style={{ color: "white", fontSize: 14 }}>
              Enter New Password
            </Box>
          </Grid>

          <Grid item sm={12} md={12} lg={4} style={{ width: "100%" }}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                id="password"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                type="password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setError("");
                }}
                label="Password"
                sx={{
                  ...inputCss,
                }}
                placeholder=""
              />
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={buttonCss}
                onClick={resetPassword}
              >
                Reset Password
              </Button>

              <ErrorBox variant="body2">{error}</ErrorBox>
              <TrackedLink eventName='reset - link - home' to="/">Back to Home</TrackedLink>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
