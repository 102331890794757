import React from "react";
import { useStytchUser } from "@stytch/react";
import { Navigate } from "react-router-dom";

// ** MUI Components

const LandingHome = () => {
  const { user } = useStytchUser();

  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  localStorage.setItem("couponCode", code);

  if (user) {
    return <Navigate to={`/packages`} />;
  } else {
    return <Navigate to={`/signup`} />;
  }

  // return (
  //   <div>
  //     <Grid container justifyContent={"center"}>
  //       <Grid item sm="12" lg="6">
  //         <nav className="welcome">
  //           <Link className="main spartan" to="/signup">
  //             Get Started
  //             <br />
  //             <span>Free 7 day trial</span>
  //           </Link>
  //           <Link className="secondary spartan" to="/auth">
  //             Login
  //           </Link>
  //           <br />
  //           <Link className="third small spartan" to="/reset">
  //             Reset Password
  //           </Link>
  //         </nav>
  //       </Grid>
  //     </Grid>
  //   </div>
  // );
};

export default LandingHome;
