import React from "react";
// Add useState
import { useState, useEffect } from "react";
import { useStytchUser, useStytchSession } from "@stytch/react";
import { Navigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";

import "./style.css";

// ** MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Tile from "../components/Tile";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { LinkStyled } from "../components/CustomElements";
import CircularProgress from "@mui/material/CircularProgress";
import { getActiveSubsAndGuides } from "../services/subscription-api";
import { getSubsHeadline } from "../helpers/common";
import { getStytchSessionToken } from "../services/stytch.service";

const API_URL = process.env.REACT_APP_API_URL;

const packages = [
  {
    imgUrl: "/assets/package1.jpeg",
    title: "Purchase a Guide",
    link: "/guides",
    type: "guides",
    tagging: "packages - link - guides",
    isLive: true,
    description:
      "Choose to purchase a single guide to use in the STRNG app. You can select guides from either Lisa or Romane with focuses on getting lean or building muscle. These guides will help you achieve results according to your goals. Complete a guide and choose to upgrade to a premium membership with access to the whole STRNG app!",
  },
  {
    imgUrl: "/assets/package2.jpeg",
    title: "Subscribe",
    link: "/subscriptions",
    type: "subscription",
    tagging: "packages - link - subscribe",
    isLive: true,
    description:
      "This is our ultimate package. Access our entire library of fitness guides, exercise content, nutritional plans, recipes and classes as well as your own personalized training plan. The STRNG app guides you while offering you the opportunity to take control of your plan so you can be certain that you have the power to make your health a priority. Start a 7 day free trial (monthly subscriptions only). You can cancel at any point during the trial and you won’t be charged until you complete it. ",
  },
];

export const Members = ({ onLogin }) => {
  // Get the Stytch User object if available
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  const { session_id: sessionId } = session || {};

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const query = new URLSearchParams(window.location.search);
  const showOffline = query.get("showOffline");

  //Fetch all active subscriptions for the user.
  const [subscriptions, setSubscriptions] = useState([]);
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      setLoading(true);

      // fetch the user's active subscriptions
      const subsData = await getActiveSubsAndGuides(
        user.emails[0].email,
        getStytchSessionToken()
      );
      if (subsData?.subscriptions) {
        setSubscriptions(subsData.subscriptions);
      }

      // set guides
      if (subsData?.nonSubscriptions) {
        setGuides(subsData.nonSubscriptions);
      }

      setLoading(false);
    };

    // call the function
    if (user) {
      fetchData().catch(console.error);
    }
  }, [user]);

  return (
    <>
      {!user ? (
        // If the user is logged in, redirect to the member page
        <Navigate to="/auth" />
      ) : (
        <>
          <Box className="content-center">
            {/* Show warning to user if they have an active subscription */}
            {subscriptions.length > 0 || guides.length > 0 ? (
              <Box
                sx={{
                  bgcolor: "#33c4eb",
                  color: "white",
                  p: 1,
                  mb: 5,
                  borderRadius: 1,
                }}
              >
                <Typography variant="paraOpen" style={{ color: "black" }}>
                  {getSubsHeadline(subscriptions, guides)} Manage your account
                  here
                  <LinkStyled
                    style={{ color: "black", marginLeft: 7, fontSize: 16 }}
                    to="/manage-membership"
                  >
                    Manage Membership{" "}
                  </LinkStyled>
                </Typography>
              </Box>
            ) : (
              ""
            )}

            <Box sx={{ zIndex: 1 }}>
              <Box>
                <Box sx={{ mb: matches ? 3 : 1 }}>
                  <Typography variant="h3">CHOOSE YOUR OPTION BELOW</Typography>
                </Box>

                <Grid container justifyContent={"center"} p={3}>
                  {loading ? (
                    <Grid
                      item
                      xs={12}
                      p={0}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {packages
                    .filter((v) => (showOffline ? true : v.isLive))
                    .map((packageObj, key) => (
                      <Grid
                        item
                        xs={12}
                        p={0}
                        key={key}
                        lg={3}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Tile
                          imgUrl={packageObj.imgUrl}
                          title={packageObj.title}
                          loading={loading}
                          description={packageObj.description}
                          link={packageObj.link}
                          hasActiveSubs={subscriptions?.length ? true : false}
                          type={packageObj.type}
                          matches={matches}
                          tagging={packageObj.tagging}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default Members;
