import React from "react";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import { TrackedLink } from "../components/CustomElements";
import StrngButton from "../components/StrngButton";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const query = new URLSearchParams(window.location.search);
    const error = query.get("error");
    const navigate = useNavigate();

    return (
        <section>
            <Grid container flexDirection={"column"}>
                <Grid item sm="12">
                    <div className="product Box-root">
                        <div className="description Box-root">


                            <Grid container flexDirection={"column"} alignItems={"center"}>
                                <Grid item sm="6">
                                    <Typography variant="h3">404 - Not Found </Typography>

                                </Grid>
                                <Grid item sm="6" alignItems={"right"}>
                                    <StrngButton sx={{ mt: 5 }} onClick={() => { navigate('/') }}
                                        text={`Home`} ></StrngButton>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </Grid>
                <Grid item sm="12">
                    {/* Playstore and AppStore Download links with images */}
                    <h3 style={{ color: "white", fontSize: "18px", marginTop: "0em" }}>
                        Download the App.
                    </h3>
                    <div
                        className="product Box-root"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                        }}
                    >
                        <div className="Box-root">
                            <TrackedLink
                                eventName='notfound - download - ios'
                                to="https://apps.apple.com/us/app/strng/id1492688256?ls=1"
                                style={{
                                    margin: 10,
                                }}
                            >
                                <img
                                    src={"/assets/download_ios.svg"}
                                    className="app-icon"
                                    alt="logo"
                                />
                            </TrackedLink>
                        </div>
                        <div className="Box-root">
                            <TrackedLink
                                eventName='notfound - download - android'
                                to="https://play.google.com/store/apps/details?id=com.strong.andsxy&pli=1"
                                style={{ ":hover": { opacity: "0.7", margin: 10 } }}
                            >
                                <img
                                    src={"/assets/download_android.svg"}
                                    className="app-icon"
                                    alt="logo"
                                />
                            </TrackedLink>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default NotFound;
