import React, { useState, useCallback } from "react";
import { useStytch, useStytchUser } from "@stytch/react";
import { Navigate } from "react-router-dom";
import customCss from "../constants/customCss";
import mpTracker from '../library/mixpanel';
import "./style.css";

// ** MUI Components
import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import GridBase from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { isValidEmail } from "../constants/isValidEmail";
import { ErrorBox, TermsBox, TrackedLink } from "../components/CustomElements";
import { errorMap } from "../constants/errorMsgMap";

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
}
`;
export const Login = ({ onLogin }) => {
  const { inputCss, buttonCss } = customCss;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user } = useStytchUser();
  // Get the Stytch User object if available
  const stytchClient = useStytch();


  const login = async () => {
    //Validate
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      mpTracker.error_track('Login Error', {message: 'Please enter a valid email address'});
      return;
    }
    if (!password) {
      setError("Password is required");
      mpTracker.error_track('Login Error', {message: 'Password is required'});
      return;
    }

    try {
      const authUser = await stytchClient.passwords.authenticate({
        email,
        password,
        session_duration_minutes: 60,
      });


      const authUserResponse = authUser?.user;
      const stytchId = authUser?.user_id;

      mpTracker.track('Login Success - Stytch'); 
      let mixpanel_email = authUserResponse.emails[0].email;
      let mixpanel_firstName = authUserResponse.name.first_name;
      let mixpanel_lastName = authUserResponse.name.last_name;
      let mixpanel_user_id = authUserResponse.user_id;
      mpTracker.setPeopleProperties(mixpanel_user_id, mixpanel_firstName, mixpanel_lastName, mixpanel_email);
    } catch ({ error_type: errorType }) {
      console.error("Login failed:", error);
      setError(errorMap[errorType] || errorMap["default"]);
      mpTracker.error_track('Login Error', {message: errorMap[errorType] || errorMap["default"]});
    }
  };

  if (user) {
    return <Navigate to="/packages" />;
  }
  return (
    <>
      <Box className="content-center">
        <Grid
          container
          fullWidth
          sx={{ zIndex: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Grid sm={12} md={12} lg={4} sx={{ mb: 4 }} style={{ width: "100%" }}>
            <Typography variant="h3">LOGIN</Typography>
          </Grid>
          <Grid item sm={12} md={12} lg={4} style={{ width: "100%" }}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                label="Email"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                sx={{
                  ...inputCss,
                  marginBottom: 3,
                }}
                placeholder="hello@strngofficial.com"
              />
              <TextField
                fullWidth
                id="password"
                InputLabelProps={{
                  style: { color: "#7d9297" },
                }}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                label="Password"
                sx={{
                  ...inputCss,
                  marginBottom: 3,
                }}
                placeholder=""
              />
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={buttonCss}
                onClick={login}
              >
                Login
              </Button>

              <ErrorBox variant="body2">{error}</ErrorBox>

              <TermsBox>
                <Typography
                  variant="body2"
                  style={{ color: "white", fontWeight: 300, marginBottom: 10 }}
                >
                  Forgot Password?{" "}
                  <TrackedLink eventName='login - link - forgot' fontWeight="bold" to="/forgot">
                    Reset
                  </TrackedLink>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "white", fontWeight: 300 }}
                >
                  Don't have an account?{" "}
                  <TrackedLink eventName='login - link - register' fontWeight="bold" to="/signup">
                    Register
                  </TrackedLink>
                </Typography>
              </TermsBox>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Login;
