import React from "react";
import { useState, useEffect } from "react";

import { useStytchUser, useStytchSession } from "@stytch/react";
import { Navigate } from "react-router-dom";
import "./style.css";

// ** MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tile from "../components/TileGuides";

import { guidesProd } from "../constants/guides";
import { guidesStaging } from "../constants/guides-staging";
import { getActiveSubsAndGuides } from "../services/subscription-api";
import { CircularProgress } from "@mui/material";

import { getStytchSessionToken } from "../services/stytch.service";

let guides =
  process.env.REACT_APP_VERCEL_ENV === "prod" ||
    process.env.REACT_APP_VERCEL_ENV === "production"
    ? guidesProd
    : guidesStaging;

export const Guides = () => {
  // Get the Stytch User object if available
  const { user } = useStytchUser();
  const { session } = useStytchSession();

  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [activeGuides, setActiveGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const showTestProduct = query.get("showTestProduct");

  if (!window.location.href.includes("my.strngapp.com")) {
    guides = guidesProd;
  }

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      setLoading(true);

      // fetch the user's active subscriptions
      const subsData = await getActiveSubsAndGuides(user.emails[0].email, getStytchSessionToken());
      if (subsData?.subscriptions) {
        setActiveSubscriptions(subsData.subscriptions);
      }

      // set guides
      if (subsData?.nonSubscriptions) {
        setActiveGuides(subsData.nonSubscriptions);
      }

      setLoading(false);
    };

    // call the function
    if (user) {
      fetchData().catch(console.error);
    }
  }, [user]);

  if (!loading && activeSubscriptions.length) {
    return <Navigate reloadDocument={true} to="/manage-membership" />;
  }
  return (
    <>
      {!user ? (
        // If the user is logged in, redirect to the member page
        <Navigate to="/auth" />
      ) : (
        <>
          <Box className="content-center">
            <Grid
              container
              sx={{ zIndex: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              mt={{ lg: 5 }}
              mb={{ lg: 5 }}
            >
              {loading ?
                <Grid
                  item
                  xs={12}
                  p={0}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
                :
                <Grid item sm={12} md={12} lg={8}>

                  <Box sx={{ mb: 6, padding: "0 20px" }}>
                    <Typography variant="h3">Our Guides </Typography>
                    <Typography variant="paraOpen" mt={2}>
                      Sign up to our upcoming challenges and push your fitness up
                      to new levels. Here you can find Lisa and Romane’s
                      multi-week long workout guides which are available for
                      one-off purchase. Guides are similar to a subscription plan
                      but not personalised. You can unlock all of these guides,
                      plus loads more content when you open a subscription with
                      the STRNG app.
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    {guides
                      .filter((v) => (showTestProduct ? true : !v.isTest))
                      .map(
                        (
                          {
                            title,
                            subtitle,
                            description,
                            features,
                            img,
                            path,
                            productId,
                            stripeId,
                            price,
                            tagging,
                          },
                          key
                        ) => (
                          <Grid
                            item
                            xs={12}
                            key={key}
                            lg={4}
                            display={"flex"}
                            justifyContent={"space-around"}
                          >
                            <Tile
                              title={title}
                              subtitle={subtitle}
                              description={description}
                              features={features}
                              img={img}
                              path={path}
                              productId={productId}
                              stripeId={stripeId}
                              price={price}
                              loading={loading}
                              tagging={tagging}
                              activeGuides={activeGuides}
                            />
                          </Grid>
                        )
                      )}
                  </Grid>
                </Grid>
              }
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
export default Guides;
