import React from "react";
import { Link } from "react-router-dom";
import { useStytch, useStytchUser } from "@stytch/react";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import { TrackedLink } from "../components/CustomElements";

const SuccessPage = ({ sessionId }) => {
  return (
    <section>
      <Grid container flexDirection={"column"}>
        <Grid item sm="12">
          <div className="product Box-root">
            <div className="description Box-root">
              <Typography variant="h3">Thank you </Typography>
              <h3
                style={{ color: "white", fontSize: "22px", marginTop: "1em" }}
              >
                Your recent purchase of a STRNG subscription or guide was successful.
              </h3>
              <h3
                style={{ color: "white", fontSize: "18px", marginTop: "3em" }}
              >
                Download the app to get started.
              </h3>
            </div>
          </div>
        </Grid>
        <Grid item sm="12">
          {/* Playstore and AppStore Download links with images */}

          <div
            className="product Box-root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div className="Box-root">
              <TrackedLink
                eventName='success page - download - ios'
                to="https://apps.apple.com/us/app/strng/id1492688256?ls=1"
                style={{
                  margin: 10,
                }}
              >
                <img
                  src={"/assets/download_ios.svg"}
                  className="app-icon"
                  alt="logo"
                />
              </TrackedLink>
            </div>
            <div className="Box-root">
              <TrackedLink
                eventName='success page - download - ios'
                to="https://play.google.com/store/apps/details?id=com.strong.andsxy&pli=1"
                style={{ ":hover": { opacity: "0.7", margin: 10 } }}
              >
                <img
                  src={"/assets/download_android.svg"}
                  className="app-icon"
                  alt="logo"
                />
              </TrackedLink>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form> */}
    </section>
  );
};

export default SuccessPage;
