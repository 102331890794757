import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import mpTracker from '../library/mixpanel';

// Define your custom button component
const StrngButton = ({ 
  text,
   onClick, 
   eventName, 
   sx = {}, 
   size = "large", 
   showFullWidth = true, 
   callback = null, 
   type = "submit" 
}) => {

  const handleClick = () => {
    if (eventName) {
      mpTracker.track(eventName);
    }
    if (onClick) {
      onClick(); // after mixpanel is triggered above, buy the guide
    } else if (callback) {
      callback();
    } 
  };

 
  return (
    <Button
      fullWidth={showFullWidth ? true : false}
      size={size}
      variant="contained"
      sx={{
        ":hover": {
          bgcolor: "#454545", // theme.palette.primary.main
          color: "white",
          cursor: "pointer",
        },
        marginBottom: 2,
        fontWeight: 700,
        backgroundColor: "#33c4eb",
        borderRadius: "20px",
        color: "black",
        cursor: "pointer",
        ...sx,
      }}
      type= {type}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

StrngButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  eventName: PropTypes.string,
};

export default StrngButton;
