

const parseCookies = (cookieHeader) => {
    const list = {};
    if (!cookieHeader) return list;
  
    cookieHeader.split(`;`).forEach(function (cookie) {
      // eslint-disable-next-line prefer-const
      let [name, ...rest] = cookie.split(`=`);
      name = typeof name === 'string' ? name.trim() : null;
      if (!name) return;
      const value = rest.join(`=`).trim();
      if (!value) return;
      list[name] = decodeURIComponent(value);
    });
  
    return list;
  };

  export const getStytchSessionToken = () => {

    const cookies = parseCookies(document.cookie);
    // fetch the user's active subscriptions
    return cookies?.stytch_session;
  }