import React, { useState } from "react";
import "./style.css";

// ** MUI Components
import Box from "@mui/material/Box";
import { useEffect } from "react";
import Divider from '@mui/material/Divider';

// import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GridBase from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { LinkStyled } from "../components/CustomElements";
import { Navigate } from "react-router-dom";
import { useStytchUser, useStytchSession, withStytch, withStytchSession } from "@stytch/react";

import TileManageSubs from "../components/TileManageSubs";
import { getActiveSubsAndGuides } from "../services/subscription-api";

import { CircularProgress } from "@mui/material";
import { getSubsHeadline } from "../helpers/common";

import { getStytchSessionToken } from "../services/stytch.service";

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`;

const API_URL = process.env.REACT_APP_API_URL;

const ManageMembership = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [guides, setGuides] = useState([]);
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  const { session_id: sessionId } = session || {};

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      setLoading(true);
      // fetch the user's active subscriptions
      const subsData = await getActiveSubsAndGuides(user.emails[0].email, getStytchSessionToken());
      if (subsData?.subscriptions) {
        setSubscriptions(subsData.subscriptions);
      }

      // set guides
      if (subsData?.nonSubscriptions) {
        setGuides(subsData.nonSubscriptions);
      }

      setLoading(false);
    };

    // call the function
    if (user) {
      fetchData().catch(console.error);
    }
  }, [user]);

  console.log("setGuides", guides)

  if (!user) {
    // If the user is not logged in, redirect to the Login page
    return <Navigate to="/auth" />
  }
  return (
    <>
      <Box className="content-center">
        <Grid
          container
          fullWidth
          sx={{ zIndex: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            sx={{ mb: 6 }}
            style={{ width: "100%" }}
          >
            <Typography variant="h3">Manage Account </Typography>

            <Typography variant="paraOpen" mt={2}>
              {/* Please enter your email below to recover your account using an
              email code.{" "} */}
            </Typography>
            {subscriptions.length > 0 || guides.length ? (
              <Box
                sx={{
                  bgcolor: "#33c4eb",
                  color: "white",
                  p: 1,
                  mb: 5,
                  borderRadius: 1,
                }}
              >
                <Typography variant="paraOpen" style={{ color: "black" }}>
                  {getSubsHeadline(subscriptions, guides)}

                </Typography>
              </Box>
            ) : ''}
          </Grid>

          <Grid item sm={12} md={12} lg={6} style={{ width: "100%" }}>

            {/* {Show users's subscriptions in tabular format with a button to let user cancel the subscription} */}

            {/* Create row to display one subscriptions */}
            {loading ?
              <Grid
                item
                xs={12}
                p={0}
                display={"flex"}
                justifyContent={"center"}
              >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </Grid>
              : ''
            }
            <Box style={{ marginBottom: '4em' }}>
              <Grid container justifyContent={"center"} p={3}>
                {!loading && subscriptions.length ? <Divider sx={{
                  width: '100%',
                  "&::before, &::after": {
                    borderColor: "white",
                  },
                  color: "white",
                  "& .MuiDivider-wrapper": {
                    color: "white"
                  },

                  marginBottom: "2em",
                }}>SUBSCRIPTIONS</Divider> : ''
                }
                {subscriptions.map((subscription, key) => (
                  <Grid item xs={12} p={0} key={key} display={"flex"}>
                    <TileManageSubs subscription={subscription} />
                  </Grid>
                ))}

                {!loading && guides.length ? <Divider sx={{
                  width: '100%',
                  "&::before, &::after": {
                    borderColor: "white",
                  },
                  color: "white",
                  "& .MuiDivider-wrapper": {
                    color: "white"
                  },
                  marginTop: "8em",
                  marginBottom: "2em",
                }}>GUIDES</Divider> : ''
                }

                {guides.map((guide, key) => (
                  <Grid item xs={12} p={0} key={key} display={"flex"}>
                    <TileManageSubs guide={guide} type={'guide'} />
                  </Grid>
                ))}

                {!loading && !guides.length && !subscriptions.length && <Typography variant="paraOpen" sx={{ color: "#fff" }}>You have no active subscriptions or guides.</Typography>}
              </Grid>
            </Box>
            {/* //Show subscriptions in tabular format */}
            <LinkStyled style={{ color: "white" }} to="/">
              Back to Home
            </LinkStyled>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default ManageMembership;
