export const errorMap = {
  unauthorized_credentials:
    "You've entered an incorrect password. Please try again or click the 'Forgot Password' link below.",
  email_not_found:
    "Whoops! This email doesn't appear to be in our system. Please sign up or try a new one.",
  too_many_requests: "Too many requests. Please try again later.",
  internal_server_error:
    "Oops, something seems to have gone wrong. Please try again later.",
  weak_password:
    "Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.",
  breached_password:
    "Breached Password. Password appears in a list of breached passwords.",
  unable_to_auth_magic_link:
    "Whoops. It looks like this link was already used or has expired. Please try again.",
  default: "Oops, something seems to have gone wrong. Please try again later.",
};
