import React, {useState} from "react";
import { useStytchUser } from "@stytch/react";
import { Navigate, useParams } from "react-router-dom";
import "./style.css";
import {ErrorBox } from "../components/CustomElements";

// ** MUI Components
import { ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  CardMedia,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { guidesProd } from "../constants/guides";
import { guidesStaging } from "../constants/guides-staging";
import StrngButton from "../components/StrngButton";
import StrngBackHomeButton from "../components/StrngBackHomeButton";

const guides =
  process.env.REACT_APP_VERCEL_ENV === "prod" ||
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? guidesProd
    : guidesStaging;

const API_URL = process.env.REACT_APP_API_URL;

export const GuideDetail = () => {
  const { id } = useParams();

  const { user } = useStytchUser();
  const { user_id: stytchId, emails } = user || {};
  const email = emails?.[0]?.email;

  const guide = guides.find((guide) => guide.path === `/guides/${id}`);
  const { img } = guide;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));


  const [error, setError] = useState("");
  const handleSubmit = async (event) => {

    const response = await fetch(`${API_URL}/v1/payment/create-checkout-product`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        stytchId: stytchId,
        couponCode: localStorage.getItem("couponCode"),
        price_id: guide.stripeId,
        customerEmail: email,
      }),
    });

    const data = await response.json();
    if (response.status === 200) {
      window.location.href = data.url;
      return true;
    }
    setError("Please reload page and try again");
  };  

  return (
    <>
      {!user ? (
        // If the user is logged in, redirect to the member page
        <Navigate to="/auth" />
      ) : (
        <>
          <Box className="content-center">
            <Box sx={{ zIndex: 1 }}>
              <Grid
                container
                sx={{ zIndex: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 20px",
                }}
                mt={{ lg: 5 }}
                mb={{ lg: 5 }}
              >
                <Grid item sm={12} lg={10}>
                  <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item sm="12" md="12" lg="5">
                      <CardMedia
                        component="img"
                        sx={{
                          objectPosition: "top",
                          borderRadius: "20px",
                          height: "100%",
                        }}
                        image={`/assets/guides/${img}`}
                      />
                    </Grid>
                    <Grid
                      item
                      sm="12"
                      md="12"
                      lg="6"
                      ml={{ lg: 5 }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box mb={3} mt={matches ? 0 : 3}>
                        <Typography variant="h3">{guide?.title}</Typography>
                      </Box>
                      <Typography
                        variant="paraOpen"
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {guide.description}
                      </Typography>
                      <Box style={{ color: "white", fontSize: 14 }}>
                        <List
                          dense={true}
                          sx={{
                            listStyleType: "disc",
                            pl: 2,
                            color: "white",
                            mt: 3,
                          }}
                        >
                          {guide?.features?.map((benefit) => {
                            return (
                              <ListItem
                                className="open"
                                sx={{ display: "list-item", padding: "0px" }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography variant="paraOpen">
                                      {benefit}
                                    </Typography>
                                  }
                                  secondary={false ? "Secondary text" : null}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                      {
                        guide.extraDescription && (
                          <Box textAlign={"left"}>
                            <Typography variant="paraOpen">{guide.extraDescription}</Typography>
                          </Box>
                        )
                      }
                      <Box
                        justifyContent={"center"}
                        mt={5}
                        style={{ width: "100%" }}
                      >
                        <Box item sm="12" lg="12">
                          <form
                            action={`${API_URL}/v1/payment/create-checkout-product`}
                            method="POST"
                          >
                            <input
                              type="hidden"
                              name="couponCode"
                              value={`${localStorage.getItem("couponCode")}`}
                            />
                            <input
                              type="hidden"
                              name="stytchId"
                              value={`${stytchId}`}
                            />
                            <input
                              type="hidden"
                              name="price_id"
                              value={`${guide.stripeId}`}
                            />
                            <input
                              type="hidden"
                              name="customerEmail"
                              value={`${email}`}
                            />

                            <StrngButton
                              sx={{
                                width: matches ? "70%" : "100%",
                              }}
                              text={`£${guide?.price} Buy Now`}
                              eventName={`guides - buy - ${guide.revenueCat}`}
                              callback={handleSubmit}
                              type="button"
                            ></StrngButton>
                            <StrngBackHomeButton
                              sx={{
                                width: matches ? "70%" : "100%",
                              }}
                              eventName={`guides - link - back to guides`}
                              to="/guides"
                              text={`Back to Guides`}
                            ></StrngBackHomeButton>
                          </form>
                        <ErrorBox variant="body2">{error}</ErrorBox>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default GuideDetail;
