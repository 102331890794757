import React from "react";
import "./style.css";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Paper, ListItemText } from "@mui/material";
import { ListItemStyled, TrackedLink } from "../components/CustomElements";
import StrngButton from "../components/StrngButton";
import { useNavigate } from "react-router-dom";



const Terms = () => {
  const navigate = useNavigate();

  return (
    <section>
      <Grid container flexDirection={"column"} alignItems={"center"}>
        <Grid item sm="12" md="8">
          <Paper elevation={3} style={{ padding: "2em", textAlign: "left" }}>
            <Grid container flexDirection={"column"} alignItems={"center"}>
              <Grid item sm="6">
                <Typography variant="h3">
                  <strong>Terms and Conditions</strong>
                </Typography>
              </Grid>
              <Grid item sm="6" alignItems={"right"}>
                <StrngButton sx={{ mt: 5 }} onClick={() => { navigate('/') }}
                  text={`Home`} ></StrngButton>
              </Grid>
            </Grid>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>
                      Our Sale of Goods (App Product, Digital Content, and
                      Physical Products) Terms and Conditions
                    </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>

            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>1. These terms</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>What these terms cover</strong>. These are the terms and
              conditions on which we supply Products (goods, digital, or app
              product) to you.
            </Typography>
            <Typography>
              <strong>Why you should read them</strong>. Please read these terms
              carefully before making any purchase on our site. These terms tell
              you who we are, how we will provide products to you, refunds, and
              returns, and other important information.
            </Typography>
            <Typography>
              <strong>
                By purchasing any products, whether goods, digital, or app
                product, you confirm that you accept these terms and agree to
                comply with them. We may amend these terms from time to time.
                Every time you wish to purchase any products on our site, please
                check these terms to ensure you understand the terms that apply
                at that time.
              </strong>
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>
                      2. Information about us and how to contact us
                    </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Who we are</strong>. We are STRONG&SXY LTD, a company
              set up in Dubai, UAE. Our company license number is 2113937.01,
              and our registered office is at Sharjah Media City, Sharjah, UAE.
              Our brand is Strng.
            </Typography>
            <Typography>

              <strong>How to contact us</strong>. You can contact us by writing
              to us at hello@strngofficial.com.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>3.Our contract with you</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>How we will accept your order</strong>.Our acceptance of
              your order will take place when we email you to accept it, at
              which point a contract will come into existence between you and
              us.
            </Typography>
            <Typography>
              <strong>If we cannot accept your order</strong>. If we are unable
              to accept your order, we will inform you of this and will not
              charge you for the product. This might be because the product is
              out of stock, because of unexpected limits on our resources which
              we could not reasonably plan for, because we have identified an
              error in the price or description of the product or because we are
              unable to meet a delivery deadline you have specified.
            </Typography>
            <Typography>
              <strong>Your order number.</strong>.We will assign an order number
              to your order and tell you what it is when we accept your order.
              It will help us if you can tell us the order number whenever you
              contact us about your order.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>4. Our Products</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Products may vary slightly from their pictures.</strong>
              The images of the products on our website are for illustrative
              purposes only. Although we have made every effort to display the
              colours accurately, we cannot guarantee that a device's display of
              the colours accurately reflects the colour of the products. Your
              product may vary slightly from those images.
            </Typography>
            <Typography>
              <strong>Product packaging may vary.</strong>
              The packaging of the product may vary from that shown in images on
              our website.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>5. Our rights to make changes</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Minor changes to the products.</strong>
              We may change the product:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                {" "}
                to reflect changes in relevant laws and regulatory requirements;
                and
              </ListItemStyled>
              <ListItemStyled>
                {" "}
                to implement minor technical adjustments and improvements. These
                changes will not affect your use of the product.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>Updates to App product.</strong>
              We may update or require you to update the app.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>6. Delivering the products</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Delivery costs.</strong>
              The costs of delivery will be as displayed to you on our website.
              For any overseas deliveries, there may be additional import
              tax/duty, depending on the size and value of the goods, which you
              shall be responsible for paying. Please see
              https://www.easyship.com/en-gb/duties-and-taxes-calculator/ for
              further information.
            </Typography>
            <Typography>
              <strong> When we will deliver the products</strong> During the
              order process we will let you know when we will deliver the
              products to you.
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                <strong>If the products are goods.</strong>
                If the products are goods, we will deliver them to you as soon
                as reasonably possible and in any event within 30 days after the
                day on which we accept your order.
              </ListItemStyled>
              <ListItemStyled>
                <strong>If purchasing an app product. </strong>
                The app will be immediately available for your use you as soon
                as you subscribe and on acceptance of payment.
              </ListItemStyled>
              <ListItemStyled>
                <strong>
                  {" "}
                  If the product is a one-off purchase of digital content.
                </strong>
                The digital content will be immediately available for your use
                as soon as you subscribe and on acceptance of payment.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>
                {" "}
                We are not responsible for delays outside our control.
              </strong>{" "}
              If our supply of the products is delayed by an event outside our
              control, we will contact you as soon as possible to let you know
              and we will take steps to minimise the effect of the delay.
              Provided we do this we will not be liable for delays caused by the
              event.
            </Typography>
            <Typography>
              <strong>
                {" "}
                If you are not at home when the product is delivered.
              </strong>{" "}
              If no one is available at your address to take delivery and the
              products cannot be posted through your letterbox, the courier may
              leave you a note informing you of how to rearrange delivery or
              collect the products from a local depot.
            </Typography>
            <Typography>
              <strong> If you do not re-arrange delivery.</strong> If you do not
              collect the products from us as arranged or if, after a failed
              delivery to you, you do not re-arrange delivery or collect them
              from a delivery depot we will contact you for further instructions
              and may charge you for storage costs and any further delivery
              costs. If, despite reasonable efforts, the courier service is
              unable to contact you or re-arrange delivery or collection we may
              end the contract and we shall not be responsible for refunds and
              accepting returns from you.
            </Typography>
            <Typography>
              <strong>When you become responsible for the goods. </strong>{" "}
              Physical products will be your responsibility from the time we
              deliver the product to the address you gave us.
            </Typography>
            <Typography>
              <strong>When you own goods. </strong> You own the physical product
              once we have received payment in full.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>7. App Product</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>App Payments.</strong>{" "}
            </Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                All app subscription payments are handled through the Apple and
                Google app stores and are under their terms and conditions. All
                web subscription payments are handled by Stripe and are bound by
                these terms and conditions.
              </ListItemStyled>
              <ListItemStyled>
                PLEASE NOTE: Depending on which country you are in, VAT may be
                added to the price, according to Apple and Android legislation.
              </ListItemStyled>
              <ListItemStyled>
                Other currencies are set by Apple or Google directly. For
                payments made via web, conversion to your home currency will be
                handled by your account provider and a conversion fee may be
                charged for this process.{" "}
              </ListItemStyled>
            </List>
            <Typography>
              <strong>
                Subscribing will give you access to all features the app offers.
              </strong>{" "}
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                If you decide to purchase the app, you agree to instantly pay
                the price shown upon confirmation of purchase.
              </ListItemStyled>
              <ListItemStyled>
                Payment will be charged to the payment account linked to the
                card (if you are subscribing via web) or account you are using
                (the Apple or Google account connected to your device).{" "}
              </ListItemStyled>
              <ListItemStyled>
                If it is not cancelled earlier on, a subscription will
                automatically renew within 24 hours before the end of the
                current subscription period.
              </ListItemStyled>
              <ListItemStyled>
                Your account will be charged for renewal within 24 hours before
                the end of the current subscription period.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>
                You have the right to manage your subscription and auto-renewal
                may be turned off at any point after purchase.
              </strong>{" "}
            </Typography>
            <Typography>
              <strong>
                The amount you paid for your subscription upon purchase is
                non-refundable even if the subscription is terminated early.
              </strong>{" "}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>8. Digital Content</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                <strong>
                  If the product is a one-off purchase of digital content.{" "}
                </strong>
                The digital content will be immediately available for your use
                you as soon as you subscribe and on acceptance of payment.
              </ListItemStyled>
            </List>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>9. Refunds, returns and replacements</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>
                You have 14 days to return your supplement products (supplements
                or accessories including shakers and scoops) under our 14 day
                return period policy for this category of products. This return
                period begins the day after you received the item. When you
                receive your item you must check it as soon as possible
                following receipt and always before use. In order to receive a
                refund, all products must be returned unused, unopened, intact
                in the original packaging they were in when received with a
                completed returns slip supplied by STRNG within the original
                parcel. Please ensure that when you receive an item that you
                ensure all seals or tags are still intact.
              </strong>{" "}
            </Typography>
            <Typography>
              <strong>Types of refunds:</strong>
            </Typography>

            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                <strong>
                  If what you have bought is faulty, not fit for purpose, or
                  misdescribed,
                </strong>
                you will have a right to refund or replacement, see clause 9 for
                more details.
              </ListItemStyled>
              <ListItemStyled>
                <strong>
                  If you have simply changed your mind about the product,
                </strong>
                see below. You may be able to get a refund if you are within the
                cooling-off period, but this may be subject to deductions, and
                you will have to pay the costs of return of any goods.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>How long do I have to change my mind?</strong>
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                <strong>App purchase</strong>
                Once you purchase the app, the app is immediately available to
                you and you will not be able to change your mind and receive a
                refund. You were made aware of this prior to ordering and agreed
                to this when ordering.
              </ListItemStyled>
              <ListItemStyled>
                <strong> Digital content for download or streaming. </strong>
                Once you pay for the Digital content, it will be immediately
                available to you and you will not be able to change your mind
                and receive a refund. You were made aware of this prior to
                ordering and agreed to this when ordering.
              </ListItemStyled>
              <ListItemStyled>
                <strong> Product/goods. </strong>
                You have 14 days after the day you (or someone you nominate)
                received the goods. Please note that certain items will fall
                under Regulation 28(3)(a) of The Consumer Contracts Regulations
                2013, whereby due to health protection or hygiene reasons, we
                will not be able to accept returns and give you a refund.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>
                When you don't have the right to change your mind.
              </strong>
              You do not have a right to change your mind and receive a refund
              in respect of:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                {" "}
                App products after you have downloaded and paid;
              </ListItemStyled>
              <ListItemStyled>
                {" "}
                digital products after you have subscribed and paid;
              </ListItemStyled>
              <ListItemStyled>
                {" "}
                products sealed for health protection or hygiene purposes (as
                per Regulation 28(3)(a) of The Consumer Contracts Regulations
                2013) once these have been unsealed after you receive them; or
              </ListItemStyled>
              <ListItemStyled>
                {" "}
                any goods with a value of less than £42.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>When we will pay the costs of return.</strong>
              We will pay the costs of return if the products are faulty, not
              fit for purpose or misdescribed. In all other circumstances
              (including where you are exercising your right to change your
              mind) you must pay the costs of return.
            </Typography>
            <Typography>
              <strong>How we will refund you.</strong>
              We will refund you the price you paid for the products including
              delivery costs, by the method you used for payment. However, we
              may make deductions from the price, as described below.
            </Typography>
            <Typography>
              <strong>
                Deductions from refunds if you are exercising your right to
                change your mind.{" "}
              </strong>
              If you are exercising your right to change your mind:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                {" "}
                We may reduce your refund of the price (excluding delivery
                costs) to reflect any reduction in the value of the goods if
                this has been caused by your handling them in a way which would
                not be permitted in a shop.{" "}
              </ListItemStyled>
              <ListItemStyled>
                {" "}
                The maximum refund for delivery costs will be the costs of
                delivery by the least expensive delivery method we offer. For
                example, if we offer delivery of a product within 3-5 days at
                one cost but you choose to have the product delivered within 24
                hours at a higher cost, we will only refund what you would have
                paid for the cheaper delivery option.
              </ListItemStyled>
            </List>
            <Typography>
              <strong>When your refund will be made.</strong>
              We will make any refunds due to you as soon as possible. If you
              are exercising your right to change your mind:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                color: "white",
                mt: 0,
                pt: 0,
              }}
            >
              <ListItemStyled>
                {" "}
                If the products are goods, your refund will be made within 14
                days from the day on which we receive the product back from you.
              </ListItemStyled>

              <ListItemStyled>
                {" "}
                For the avoidance of doubt, should you wish to refund the goods
                simply due to not wanting to pay for the shipping/import
                tax/duty that you have agreed to under these terms, you shall be
                entirely responsible to pay for the return of the goods to us.
                Only on receipt of the returned good will we then refund your
                payment for the goods (within 14 days).{" "}
              </ListItemStyled>
            </List>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>
                      10. Refunds and Returns for faulty goods/goods not fit for
                      purpose/misdescribed
                    </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Right to refund. </strong>
              You have a right to receive a full refund for: faulty goods; goods
              not fit for purpose; or goods that are not as described. Your
              refund shall include any reasonable delivery costs.
            </Typography>
            <Typography>
              <strong>Returning faulty goods. </strong>
              If you wish to receive a refund for faulty goods, you must send
              them back to us. We will pay the reasonable costs of postage or
              collection.
            </Typography>
            <Typography>
              <strong>Timeframe: </strong>
              You will have 30 days from the date the product is delivered to
              you to let us know that there is a problem and whether you would
              like a refund or a replacement. Once we have acknowledged that you
              would like a refund or a replacement, we shall provide such a
              refund or replacement within 14 days from when we receive the
              returned goods. If you have asked for a replacement and the
              replacement is still faulty, you will have 6 months to either get
              a refund or ask for a price reduction.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>
                      12. How to contact us for Refund and Return goods
                    </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Email. </strong>
              Email us at hello@strngofficial.com. Please provide your
              name, home address, details of the order and, where available,
              your phone number and email address.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>12. Price and payment</strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Where to find the price for the product. </strong>
              The price of the product will be the price indicated on the order
              pages when you placed your order.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>
                      13. How we may use your personal information
                    </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <TrackedLink
                eventName='terms - link - privacy'
                sx={{ color: "#000000", fontSize: 16 }}
                to={"/privacy-policy"}
              >
                <strong>How we may use your personal information.</strong>
              </TrackedLink>{" "}
              We will only use your personal information as set out in our. .
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>14. Supplements </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              You have 14 days to return your supplement products (supplements
              or accessories including shakers and scoops) under our 14 day
              return period policy for this category of products. This return
              period begins the day after you received the item. When you
              receive your item you must check it as soon as possible following
              receipt and always before use. In order to receive a refund, all
              products must be returned unused, unopened, intact in the original
              packaging they were in when received with a completed returns slip
              supplied by STRNG within the original parcel. Please ensure that
              when you receive an item that you ensure all seals or tags are
              still intact.
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">
                    <strong>15. Other important terms </strong>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              <strong>Disclaimer</strong>
            </Typography>
            <Typography>
              If you suffer from any allergies or any other health issues please
              consult your doctor or GP before starting a plan with Strong &
              Sxy. You should always obtain any appropriate professional health
              advice relevant to your circumstances.
            </Typography>
            <Typography>
              Strong & Sxy makes no warranties or representations, express or
              implied, as to the currency, accuracy, completeness, reliability,
              or suitability of the information contained or referenced in this
              app. The information is subject to professional differences of
              opinion, human error in preparing this information. The Strong &
              Sxy Plans is not liable for any loss resulting from any action
              taken or reliance made by you on any of the information or
              material provided to you. If you use, or otherwise rely on, any of
              the information in the app you are responsible for ensuring, by
              independent verification, its currency, accuracy, completeness,
              reliability and relevance to your own personal and individual
              circumstances.{" "}
            </Typography>
            <Typography>
              <strong>Pregnancy</strong>
            </Typography>
            <Typography>
              If you are pregnant or breastfeeding, please consult your health
              professional first before following this plan.
            </Typography>
            <Typography>
              Before starting any new diet and exercise programme please check
              with your doctor and clear any exercise and/or diet changes with
              them before beginning. By signing up to this app, you are
              acknowledging that you are participating voluntarily in using our
              programmes, services, and/or products, and you alone are solely
              and personally responsible for your results. You acknowledge that
              you take full responsibility for your health, life and well-being,
              as well as the health, lives and well-being of your family and
              children (born and unborn, as applicable), and all decisions now
              or in the future.
            </Typography>
            <Typography>
              We cannot and do not guarantee that you will attain a specific or
              particular result, and you accept the risk that results differ for
              each individual. The health, fitness, and nutrition success
              depends on each individual’s background, dedication, desire, and
              motivation. As with any health-related programme or service, your
              results may vary, and will be based on many variables, including
              but not limited to, your individual capacity, life experience,
              unique health and genetic profile, starting point, expertise, and
              level of commitment.
            </Typography>
            <Typography>
              <strong>We may transfer this agreement to someone else.</strong>{" "}
              We may transfer our rights and obligations under these terms to
              another organisation.
            </Typography>
            <Typography>
              <strong>
                You need our consent to transfer your rights to someone else
                (except that you can always transfer our guarantee).
              </strong>
              You may only transfer your rights or your obligations under these
              terms to another person if we agree to this in writing.
            </Typography>
            <Typography>
              <strong>
                Nobody else has any rights under this contract (except someone
                you pass your guarantee on to).
              </strong>
            </Typography>
            <Typography>
              {" "}
              This contract is between you and us. No other person shall have
              any rights to enforce any of its terms.
            </Typography>
            <Typography>
              <strong>
                Even if we delay in enforcing this contract, we can still
                enforce it later
              </strong>
              If we do not insist immediately that you do anything you are
              required to do under these terms, or if we delay in taking steps
              against you in respect of your breaking this contract, that will
              not mean that you do not have to do those things and it will not
              prevent us taking steps against you at a later date. For example,
              if you miss a payment and we do not chase you, but we continue to
              provide the products, we can still require you to make the payment
              at a later date.
            </Typography>
            <Typography>
              <strong>
                Which laws apply to this contract and where you may bring legal
                proceedings.{" "}
              </strong>
              These terms are governed by English law and you can bring legal
              proceedings in respect of the products in the English courts.
            </Typography>
            <Grid container flexDirection={"column"} alignItems={"center"}>
              <Grid item sm="4">
                <StrngButton sx={{ mt: 5 }} onClick={() => { navigate('/') }}
                  text={`Home`} ></StrngButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </section>
  );
};

export default Terms;
